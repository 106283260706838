import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { Link } from './Link'
import { theme } from '../theme'
import { VietnameseText } from './Typography/VietnameseText'

const LinkContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
  color: ${props => (props.selected ? '#DB0000' : '#6C707A')};
  font-weight: ${props => (props.selected ? 'bold' : 200)};
  text-align: center;
  transition: all 0.25s ease-in-out;
  font-weight: 500;

  &:hover {
    color: #db0000;
  }

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    height: 50px;
    margin: 0;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.3);
  }
`

const AnchorContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
  min-height: 0;
  line-height: normal;
  color: #6c707a;
  font-size: 10px;
  font-weight: 200;
  text-decoration: none;
  letter-spacing: 1.15px;
  text-align: center;
  transition: all 0.25s ease-in-out;
  font-weight: 500;

  &:hover {
    color: #db0000;
  }

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    height: 50px;
    margin: 0;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.3);
  }
`

const DonateLinkContainer = styled.a`
  width: 140px;
  height: 32px;
  background: #fc3357;
  border-color: #fc3357;
  box-shadow: 2px 3px 7px 0 rgba(193, 37, 65, 0.5);
  color: #f5f5f5;
  letter-spacing: 1.31px;
  font-size: 10px;
  font-weight: 300;
  margin-left: 25px;
  border-radius: 5px;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 0 0 25px;

  transition: all 200ms ease-in-out;

  &:hover {
    background: #ea2e50;
    border-color: #ea2e50;
    font-weight: bold;
    color: #fafafa;
  }

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    margin: 0;
    width: 100%;
    border-radius: 0;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
  }
`

export const TopBarNavButton = ({
  text,
  translation,
  path,
  selected,
  setSelected,
  setIsOpen,
}) => {
  const isSelectedAriaText = selected === text ? `Selected: ${text}.` : ''
  const ariaLabel = `${isSelectedAriaText} Click to select ${text}. Vietnamese translation: ${translation}`
  const ariaCurrent = selected === text ? { 'aria-current': 'page' } : {}
  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints.sm,
  })

  switch (text) {
    case 'DONATE':
      return (
        <DonateLinkContainer href={path} aria-label={ariaLabel}>
          <span>{text}</span>
          <VietnameseText>{translation}</VietnameseText>
        </DonateLinkContainer>
      )
    case 'BPSOS CCA':
      return (
        <AnchorContainer href={path} aria-label={ariaLabel}>
          <span>{text}</span>
          <VietnameseText>{translation}</VietnameseText>
        </AnchorContainer>
      )
    default:
      return (
        <LinkContainer
          to={path}
          selected={selected === text}
          onClick={
            selected === text
              ? null
              : () => {
                  setSelected(text)
                  isMobile && setIsOpen(false)
                }
          }
          aria-label={ariaLabel}
          {...ariaCurrent}
        >
          <span>{text}</span>
          <VietnameseText>{translation}</VietnameseText>
        </LinkContainer>
      )
  }
}
