export default {
  navBarBtnInfo: [
    {
      path: '/home',
      text: 'HOME',
      translation: 'Trang Nhà',
    },
    {
      path: '/mission',
      text: 'MISSION',
      translation: 'Sứ Mệnh',
    },
    {
      path: '/about-us',
      text: 'ABOUT US',
      translation: 'Sơ Lược về Chúng Tôi',
    },
    {
      path: 'https://www.bpsoscca.org/',
      text: 'BPSOS CCA',
      translation: '',
    },
    // {
    //   path: '/contact',
    //   text: 'CONTACT',
    //   translation: 'Liên Lạc Chúng Tôi'
    // },
    // {
    //   path: '/blog',
    //   text: 'MIND + BODY BLOG',
    //   translation: 'Trang Blog',
    //   isDonate: false
    // },
    {
      path: 'https://paypal.me/bpsoscca',
      text: 'DONATE',
      translation: 'Đóng Góp',
    },
  ],
}
