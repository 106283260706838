export const dementia = {
  disorder: 'Dementia',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is dementia?',
      summary:
        'Dementia is a degeneration of cognitive functions (ability to think, remember, problem solve, or reason) to a level that interferes with a person’s daily life and activities. This degeneration of cognitive function is a result of a significant loss of brain cells and disruption of connectivity in the brain. The severity of dementia can range from a mild state (just beginning to affect a person’s functioning) to a severe stage (a person must be completely dependent on others for basic activities of daily living). Although it is not a normal part of aging, dementia is common in advanced age and can be found in up to half of all people age 85 or older. It often accompanies a form of neurodegenerative disease, such as Alzheimer’s Disease.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Reduced short-term and long-term memory',
              'Reduced language skills',
              'Reduced visual perception',
              'Reduced problem-solving skills',
              'Reduced self-management and emotional control',
              'Reduced ability to focus and pay attention',
              'Personality changes ',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Treatments',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Medications: Central acetylcholinesterase inhibitors (stabilizes memory and thinking skills)',
              'Preventive: Consistent exercise',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.ninds.nih.gov/Disorders/All-Disorders/Dementia-Information-Page',
              "Y. de Saá Guerra, S. Sarmiento Montesdeoca, J.M. García Manso, J.M. Martín González, M. Navarro Valdivielso, D. Rodriguez Ruiz, D. Rodriguez Matoso, M. Quiroga Escudero (2017) Exercise and Alzheimer's: The body as a whole, Revista Andaluza de Medicina del Deporte, Volume 10, Issue 3, 2017, Pages 120-124, ISSN 1888-7546",
              'https://doi.org/10.1016/j.ramd.2015.11.002',
              'http://www.sciencedirect.com/science/article/pii/S1888754616300041',
            ],
          },
        ],
      },
    },
  ],
}

export const dementiaViet = {
  disorder: 'Bệnh sa sút trí tuệ',
  disorderExpanded: '(tiếng Anh: Dementia)',
  content: [
    {
      type: 'text',
      title: 'Bệnh sa sút trí tuệ là gì?',
      summary:
        'Bệnh sa sút trí tuệ miêu tả một tập hợp các triệu chứng do những sự rối loạn ảnh hưởng đến não bộ gây ra. Bệnh sa sút trí tuệ ảnh thưởng đến sự suy nghĩ, hành vi và khả năng thực hiện những công việc hàng ngày. Chức năng của não bộ bị ảnh hưởng đủ để gây cản trở đến cuộc sống xã hội hay làm việc bình thường của người bệnh. Dấu hiệu phân biệt của bệnh sa sút trí tuệ là việc mất khả năng thực hiện các hoạt động hàng ngày như là hậu quả của tình trạng suy giảm khả năng nhận thức. Các triệu chứng chính xác mà người bị sa sút trí tuệ trải nghiệm tùy thuộc vào khu vực não bộ bị hư hại do căn nguyên gây ra sa sút trí tuệ. Mức độ nghiêm trọng của bệnh sa sút trí tuệ  có thể từ nhẹ (chỉ bắt đầu ảnh hưởng  chức năng của con người) cho đến mức độ xấu (người bệnh phải hoàn toàn phụ thuộc vào người khác trong những hoạt động đơn giản hằng ngày). Mặc dầu bệnh này không phải là một phần của tuổi tác, bệnh sa sút trí nhớ thường phổ biến ở người lớn tuổi và có thể tìm thấy cho đến phân nữa của người trên 85 tuổi. Bệnh Alzheimer là hình thức phổ biến nhất của bệnh sa sút trí tuệ.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu trứng:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Suy giảm trí nhớ tạm thời và trí nhớ dài hạn',
              'Suy giảm khả năng ngôn ngữ',
              'Suy giảm nhận thức về thị giác',
              'Suy giảm khã năng giải quyết vấn đề',
              'Suy giảm sư kiềm chế cảm xúc và kiềm chế',
              'Suy giảm khả năng tập trung và chú ý',
              'Tính cách cá nhân thay đổi',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Điều trị:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Thuốc: thuốc ức chế acetylcholinesterase ( điều hòa trí nhớ và kỹ năng suy nghĩ)',
              'Ngăn ngừa: thường xuyên tập thể thao',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.ninds.nih.gov/Disorders/All-Disorders/Dementia-Information-Page',
              "Y. de Saá Guerra, S. Sarmiento Montesdeoca, J.M. García Manso, J.M. Martín González, M. Navarro Valdivielso, D. Rodriguez Ruiz, D. Rodriguez Matoso, M. Quiroga Escudero (2017) Exercise and Alzheimer's: The body as a whole, Revista Andaluza de Medicina del Deporte, Volume 10, Issue 3, 2017, Pages 120-124, ISSN 1888-7546",
              'https://doi.org/10.1016/j.ramd.2015.11.002',
              'http://www.sciencedirect.com/science/article/pii/S1888754616300041',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: dementia,
  vietnamese: dementiaViet,
}
