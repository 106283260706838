import styled from 'styled-components'

const SubTitle = styled.h2`
  font-size: 24px;
  font-weight: 400;
  color: #6c707a;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  display: block;
  margin: 0;
`
export { SubTitle }
