import adhd from '../../assets/compressed/adhd.jpg'
import alzheimers from '../../assets/compressed/alzheimers.jpg'
import anxiety from '../../assets/compressed/anxiety.jpg'
import autism from '../../assets/compressed/autism.jpg'
import bipolarDisorder from '../../assets/compressed/bipolarDisorder.jpg'
import dementia from '../../assets/compressed/dementia.jpg'
import depression from '../../assets/compressed/depression.jpg'
import ocd from '../../assets/compressed/ocd.jpg'
import parkinsons from '../../assets/compressed/parkinsons.jpg'
import personalityDisorder from '../../assets/compressed/personalityDisorder.jpg'
import ptsd from '../../assets/compressed/ptsd.jpg'
import schizophrenia from '../../assets/compressed/schizophrenia.jpg'

export const getFormattedIDAndPhotoSrc = originalID => {
  let formattedID
  let photoSrc

  switch (originalID) {
    case 'bipolar-disorder':
      formattedID = 'bipolarDisorder'
      photoSrc = bipolarDisorder
      break
    case 'personality-disorder':
      formattedID = 'personalityDisorder'
      photoSrc = personalityDisorder
      break
    case "alzheimer's":
      formattedID = 'alzheimersDisease'
      photoSrc = alzheimers
      break
    case "parkinson's-disease":
      formattedID = 'parkinsonsDisease'
      photoSrc = parkinsons
      break
    case 'adhd':
      photoSrc = adhd
      formattedID = 'adhd'
      break
    case 'autism-spectrum disorder - asd':
      photoSrc = autism
      formattedID = 'autism'
      break
    case 'anxiety':
      photoSrc = anxiety
      formattedID = 'anxiety'
      break
    case 'depression':
      photoSrc = depression
      formattedID = 'depression'
      break
    case 'dementia':
      photoSrc = dementia
      formattedID = 'dementia'
      break
    case 'ocd':
      photoSrc = ocd
      formattedID = 'ocd'
      break
    case 'ptsd':
      photoSrc = ptsd
      formattedID = 'ptsd'
      break
    case 'schizophrenia':
      photoSrc = schizophrenia
      formattedID = 'schizophrenia'
      break
    default:
      break
  }

  return { formattedID, photoSrc }
}
