import styled from 'styled-components'

const Li = styled.li`
  font-weight: 300;
  font-size: 14px;
  color: #222;
  letter-spacing: 1.23px;
  margin: 0;
  margin-top: 15px;
`
export { Li }
