import React from 'react'
import styled from 'styled-components'
import { SubTitle } from './Typography/SubTitle'

const ListTitle = styled(SubTitle)`
  margin-bottom: 1.5%;
`

const ListContent = styled.div`
  padding-left: 5%;
`

const DarkerAnchorLink = styled.a`
  color: #147694;
`

const ULStyles = styled.ul`
  padding-left: ${({ hasTitle }) => (hasTitle ? '5%' : '')};
  padding-top: ${({ hasTitle }) => (hasTitle ? '1%' : '')};
`

const List = ({ title, list }) => {
  return (
    <React.Fragment key={title}>
      <ListTitle>{title}</ListTitle>
      <ListContent>
        {list.map(({ listTitle, bullets }) => (
          <React.Fragment key={listTitle}>
            {listTitle && <ListTitle>{listTitle}</ListTitle>}
            <ULStyles hasTitle={!!listTitle}>
              {bullets.map(bullet => {
                const isLink = bullet.indexOf('http') > -1
                return isLink ? (
                  <li key={bullet}>
                    <DarkerAnchorLink href={bullet}>{bullet}</DarkerAnchorLink>
                  </li>
                ) : (
                  <li key={bullet}>{bullet}</li>
                )
              })}
            </ULStyles>
          </React.Fragment>
        ))}
      </ListContent>
    </React.Fragment>
  )
}

export { List }
