import React from 'react'
import styled from 'styled-components'
import { SubTitle } from './Typography/SubTitle'
import { ListTitle } from './Typography/ListTitle'
import { Paragraph } from './Typography/Paragraph'

const MobileSubtitle = styled(SubTitle)`
  margin-bottom: 3%;
`

const MobileListTitle = styled(ListTitle)`
  margin-bottom: 3%;
`

const MobileParagraph = styled(Paragraph)`
  padding-left: 3%;
`

const MobileOCDTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #6c707a;
`

const MobileOCDContent = ({ rows, title }) => (
  <div>
    <MobileSubtitle>{title}</MobileSubtitle>
    {rows.slice(1).map(row => {
      return row.map((roww, index) => {
        return index === 0 ? (
          <MobileListTitle key={index}>
            {rows[0][index]}: {roww}
          </MobileListTitle>
        ) : (
          <MobileParagraph key={index}>
            <MobileOCDTitle>{rows[0][index]}:</MobileOCDTitle>
            <p>{row[index]}</p>
          </MobileParagraph>
        )
      })
    })}
  </div>
)

export default MobileOCDContent
