export const personalityDisorder = {
  disorder: 'Personality Disorders',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is a personality?',
      summary:
        'Personality is the way someone thinks, feels, and behaves that sets him or her apart from other people. A personality is molded by one’s experiences, environment (surroundings, life situations), and inherited characteristics.',
    },
    {
      type: 'text',
      title: 'What is a personality disorder?',
      summary:
        'A personality disorder is a long-term way of thinking, feeling, and behaving that deviates from the expectations of culture and causes distress or problems functioning. There are currently 10 specific types of personality disorders united by a common characteristic: long-term patterns of behavior and inner experiences that are much different than what is expected. Personality disorders affect at least 10% of Americans, with the onset of personality disorders usually occurring in their later teenage years to early 30s.',
    },
    {
      type: 'list',
      title: 'Types of Personality Disorders / Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Antisocial personality disorder: A pattern of disregarding or violating others’ rights. May include a lack of conformity to social norms, tendency to lie and deceive others, and impulsive decision-making.',
              'Avoidant personality disorder: A pattern of extreme shyness, feelings of inadequacy, and extreme sensitivity to criticism. This pattern may include the inability to tolerate not being liked by others.',
              'Borderline personality disorder: A pattern of instability in personal relationships, intense emotions, poor self-image, and impulsive decision-making. This pattern may also include going to great lengths to avoid abandonment, having repeated suicide attempts, displaying inappropriate anger, or having constant feelings of emptiness.',
              'Dependent personality disorder: A pattern of needing to be cared for, submission, and clingy behavior. This pattern may also include the inability to confidently make decisions without reassurance form others.',
              'Histrionic personality disorder: A pattern of excessive emotion and attention seeking behaviors.',
              'Narcissistic personality disorder: A pattern of needing admiration and lacking empathy for others. This pattern may include an exaggerated sense of self-importance, a sense of entitlement, and a tendency to take advantage of others.',
              'Obsessive-compulsive personality disorder: A pattern of obsession with orderliness, perfection, and control that causes excessive focus on details and schedules, “workaholism”, and inflexibility in morality and values.',
              'Paranoid personality disorder: A pattern of being suspicious of others and seeing them as mean or spiteful. This pattern often includes distancing oneself from others to avoid their harmful and deceitful intentions.',
              'Schizoid personality disorder: A pattern of detachment from social relationships and little emotional expression.',
              'Schizotypal personality disorder: A pattern of being very uncomfortable in close relationships accompanied by distorted thinking and eccentric behavior.',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/personality-disorders/what-are-personality-disorders',
              'Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition (DSM-5). American Psychiatric Association. (2013).',
              'https://www.nimh.nih.gov/health/topics/borderline-personality-disorder/index.shtml',
              'https://www.nimh.nih.gov/health/statistics/personality-disorders.shtml',
              'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3105841/',
            ],
          },
        ],
      },
    },
  ],
}

export const personalityDisorderViet = {
  disorder: 'Rối loạn nhân cách',
  disorderExpanded: '(tiếng Anh: Personality disorders)',
  content: [
    {
      type: 'text',
      title: 'Tính cách là gì?',
      summary:
        'Tính cách là một tập hợp các trạng thái để biệt định các đối tượng có cách sống, cách cư xử và cách phản ứng hoàn toàn khác biệt của người này đối với người khác. Tính cách được tạo ra bởi trải nghiệm của từng người, môi trường sống (mọi thứ xung quanh, hoàn cảnh sống) và tính cách thừa hưởng.Tính cách là một tập hợp các trạng thái để biệt định các đối tượng có cách sống, cách cư xử và cách phản ứng hoàn toàn khác biệt của người này đối với người khác. Tính cách được tạo ra bởi trải nghiệm của từng người, môi trường sống (mọi thứ xung quanh, hoàn cảnh sống) và tính cách thừa hưởng.',
    },
    {
      type: 'text',
      title: 'Rối loạn nhân cách là gì?',
      summary:
        'Rối loạn nhân cách là cách suy nghĩ, cảm giác và hành động trong thời gian dài khác biệt với sự mong đợi của văn hóa. Hiện tại chúng ta có 10 loại khác nhau cua rối loạn nhân cách với những tính cách phổ biến như: các dạng hành vi bền vững và ăn sâu bộc lộ qua sự đáp ứng cứng nhắc trong các hoàn cảnh cá nhân và xã hội khác nhau. Rối loạn nhân cách ảnh hưởng khoảng 10% người dân Mỹ và thường đượcc phát hiện ở những năm cuối tuổi thiếu niên đến đầu những năm 30 tuổi.',
    },
    {
      type: 'list',
      title: 'Phân loại rối loạn nhân cách/dấu hiệu và triệu chứng:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Rối loạn nhân cách chống đối xã hội: Có biểu hiện chủ yếu bằng sự khó hoặc không thích ứng thường xuyên với các quy tắc đạo đức xã hội và pháp luật, có khuynh hướng nói dối và lừa dối những người khác, và đưa ra những quyết định bốc đồng.',
              'Rối loạn nhân cách tránh né/lẩn tránh: Có đặc điểm chung là rất mắc cỡ, cảm giác không thỏa đáng và rất nhạy cảm với những phán xét, bao gồm việc không thể chịu đựng được khi không được người khác thích.',
              'Rối loạn nhân cách ranh giới/bất định: Đặc điểm chung bao gồm nhiều hành vi không có kiểm soát và thể hiện sự bất thường thường từ cách họ đối xử với bản thân cho tới những mối quan hệ xã hội,  dễ xúc cảm mạnh mẽ, và bốc đồng. Loại bệnh này còn có thể bao gồm: lo sợ bị từ chối hoặc bỏ rơi đến mức cố gắng tránh khỏi việc tạo dựng các mối quan hệ mới, đe dọa tự tử, giận dữ một cách thái quá hoặc thường xuyên giận dữ với lý do không đáng có, và liên tục có cảm giác trống rỗng.',
              'Rối loạn nhân cách phụ thuộc: một rối loạn nhân cách được đặc trưng bởi sự phụ thuộc tâm lý lan tỏa vào người khác. Những người mắc chứng rối loạn nhân cách phụ thuộc, tỏ ra quá phụ thuộc vào người khác khi cần đưa ra quyết định.',
              'Rối loạn nhân cách kịch tính: Rối loạn này được định hình bởi các cảm xúc hời hợt, muốn được người khác chú ý bằng các hành vi mang tính mánh khóe, lôi kéo.',
              'Nhân cách yêu mình thái quá hoặc rối loạn nhân cách ái kỷ : Có biểu hiện quá ảo tưởng và hành vi tự cao tự đại, khát vọng được người khác ngưỡng mộ, tham vọng thành công chói sáng trong mọi lãnh vực và thiếu sự đồng cảm với người khác.',
              'Rối loạn nhân cách ám ảnh cưỡng chế:  Có đặc tính là sự quan tâm quá mức tới những chi tiết, quy tắc, sắp xếp trật tự và sự hoàn hảo, “người tham công tiếc việc”, không thay đổi được tiêu chuẩn đạo đức.',
              'Rối loạn nhân cách hoang tưởng : Rối loạn nhân cách hoang tưởng là loại rối loạn nhân cách lập dị trong đó người bệnh thường xuyên nghi ngờ người khác và thấy họ như “thấp kém” hoặc “đe dọa”. Các dấu hiệu khác của tình trạng này bao gồm miễn cưỡng giao tiếp với người khác, để tránh bị nguy hại và mục đích lừa lọc.',
              'Rối loạn nhân cách phân liệt: là một bệnh lý mang tính lan tỏa được định hình bởi sự cô lập xã hội và cảm xúc thờ ơ đối với xung quanh.',
              'Rối loạn nhân cách dạng phân liệt Schizotypal: Những người bị rối loạn nhân cách dạng Schizotypal thường được mô tả như là kẻ lập dị và có rất ít mối quan hệ gần gũi. Họ luôn giữ khoảng cách của người khác và không thoải mái trong các mối quan hệ. Đôi khi, họ cũng có những suy nghĩ hết sức vô lý, ý tưởng bất thường.',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/personality-disorders/what-are-personality-disorders',
              'Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition (DSM-5). American Psychiatric Association. (2013).',
              'https://www.nimh.nih.gov/health/topics/borderline-personality-disorder/index.shtml',
              'https://www.nimh.nih.gov/health/statistics/personality-disorders.shtml',
              'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3105841/',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: personalityDisorder,
  vietnamese: personalityDisorderViet,
}
