export const anxiety = {
  disorder: 'Anxiety',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is an anxiety disorder?',
      summary:
        'Anxiety disorders involve feelings of intense anxiety that can interfere with daily activities such as job performance, school work, and relationships. Unlike the temporary anxiety that is a part of everyday life, the anxious feelings in anxiety disorders do not go away may worsen over time. The different categories of anxiety disorder include: generalized anxiety disorder (pathological worrying), panic disorder (episodes of intense terror), and phobias (intense, irrational fear of something).',
    },
    {
      type: 'text',
      title: 'Misconceptions about anxiety disorder',
      summary:
        'Individuals with anxiety disorder are not just “worrisome” and overexaggerating their stress. The intense anxiety that they experience pins them down with fear and prevents them from functioning normally, sometimes even causing a physiological stress response. Dissipating these overwhelming feelings of worry is not as simple as just “calming down” and may require significant medical and therapeutic intervention.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: 'Generalized Anxiety Disorder',
            bullets: [
              'Constantly feeling unsettled',
              'Being easily exhausted',
              'Difficulty concentrating',
              'Irritable',
              'Constant muscle tension',
              'Difficulty controlling feelings of worry',
              'Difficulty sleeping',
            ],
          },
          {
            listTitle: 'Panic Disorder',
            bullets: [
              'Heart palpitations, pounding heartbeat, accelerated heart rate',
              'Sweating',
              'Trembling/shaking',
              'Difficulty breathing',
              'Feeling out of control',
            ],
          },
          {
            listTitle: 'Phobias',
            bullets: [
              'Irrational or excessive worrying about encountering the feared object or situation',
              'Taking excessive measures to avoid the feared object or situation',
              'Experiencing immediate and intense anxiety when encountering the feared object or situation',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Treatments',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Psychotherapy: Cognitive behavioral therapy',
              'Medication: Anti-anxiety drugs, antidepressants, beta-blockers',
            ],
          },
        ],
      },
    },
  ],
}

export const anxietyViet = {
  disorder: 'Rối loạn lo âu',
  disorderExpanded: '(tiếng Anh: Anxiety Disorder)',
  content: [
    {
      type: 'text',
      title: 'Rối loạn lo âu là gì:',
      summary:
        'Rối loạn lo âu liên quan đến cảm giác lo âu và sợ hãi quá mức ảnh hưởng nghiêm trọng đến cuộc sống như là thành tích trong công việc, bài vở từ trường học, và những mối quan hệ. Không giống như những lo âu tạm thời là một phần trong cuộc sống , cảm giác lo lắng trong rối loạn lo âu không thể mất đi mà tệ hơn theo thời gian. Các dạng rối loạn lo âu bao gồm: Rối loạn lo âu lan tỏa (GAD), rối loạn hoảng sợ (giai đoạn sợ hãi run rẩy), và Hội chứng sợ (nỗi sợ hãi dai dẳng đối với một đối tượng  hay vật thể nào đó)',
    },
    {
      type: 'text',
      title: 'Những quan niệm không đúng về rối loạn lo âu:',
      summary:
        'Với những người bị rối loạn lo âu không chỉ lo lắng và phóng đại quá mức sự căng thẳng của họ. Nỗi sợ hãi dữ dội  mà họ trải qua trói buột họ với sự sợ hãi và khống chế họ với những hoạt động bình thường hàng ngày, đôi khi  trong phản ứng sinh lý trong lúc căng thẳng. Để làm mất đi những cảm giác lo lắng tột độ không chỉ đơn giản làm giảm bớt độ thôi thúc gây khó chịu và nó có thể đòi hỏi phải dùng đến  thuốc và các liệu pháp tâm lý.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu chứng:',
      summary: {
        list: [
          {
            listTitle: 'Rối loạn lo âu lan tỏa:',
            bullets: [
              'Tâm trạng như luôn bất an',
              'Dễ dàng kiệt sức',
              'Khó tập trung',
              'Dễ nổi cáu',
              'Căng cứng bắp thịt',
              'Gặp khó khăn trong việc kiểm soát nỗi lo lắng',
              'Khó ngủ',
            ],
          },
          {
            listTitle: 'Rối loạn hoảng sợ:',
            bullets: [
              'Tim đập nhanh, đập thình thịch, nhịp tim tăng tốc',
              'Đổ mồ hôi',
              'Run rẩy',
              'Khó thở',
              'Cảm giác mất kiểm soát',
            ],
          },
          {
            listTitle: 'Hội chứng sợ:',
            bullets: [
              'Rối loạn lo âu là sự lo sợ quá mức trước một tình huống  xảy ra',
              'Dùng các biện pháp thái quá để lẩn tránh đi nỗi sợ hãi về một vật hay tình huống nào đó',
              'Trải qua nỗi lo sợ dữ dội và ngay lập tức khi bắt gặp một vật thể hay hoàn cảnh nào đó',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Điều trị',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Tâm lý trị liệu: Phương pháp điều trị nhận thức cố gắng điều chỉnh hành vi',
              'Thuốc: chống lo âu, chống trầm cảm , thuốc kháng beta',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: anxiety,
  vietnamese: anxietyViet,
}
