import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import posed from 'react-pose'
import { useMediaQuery } from 'react-responsive'
import { theme } from '../theme'

const pulsate = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 200, 3, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 200, 3, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 200, 3, 0);
  }
`

const LanguageButton = styled(
  posed.button({
    show: {
      delay: 1000,
      x: '20px',
      transition: {
        duration: 500,
        type: 'spring'
      }
    },
    hide: {
      x: '120px'
    }
  })
)`
  height: 36px;
  width: 140px;
  position: fixed;

  top: 80px;
  right: 0px;
  background-color: #ffc803;
  border-color: transparent;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  @media screen and (max-width: 908px) {
    top: 110px;
  }

  animation: ${pulsate} 2s infinite;

  &:hover {
    color: white;
    border-color: transparent;
  }
`

const MobileLanguageButton = styled.button`
  position: fixed;
  height: 36px;
  width: 120px;
  margin-bottom: 1.75rem;
  left: 50%;
  margin-left: -60px;
  bottom: -3px;

  background-color: #ffc803;
  border-color: transparent;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  animation: ${pulsate} 2s infinite;

  &:hover {
    color: white;
    border-color: transparent;
  }
`

const LanguageSelector = ({ onClick, children, ariaLabel }) => {
  const [animationState, setAnimationState] = useState('hide')
  useEffect(() => setAnimationState('show'), [])

  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints.sm
  })

  return !isMobile ? (
    <LanguageButton
      pose={animationState}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {children}
    </LanguageButton>
  ) : (
    <MobileLanguageButton aria-label={ariaLabel} onClick={onClick}>
      {children}
    </MobileLanguageButton>
  )
}

export { LanguageSelector }
