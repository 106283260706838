import React, { useContext } from 'react'
import styled from 'styled-components'
import { ENGLISH } from '../constants/constants'
import aboutUsJSON from '../../data/about-us'
import aboutUs from '../../assets/compressed/about-us.jpg'
import { SubTitle } from '../components/Typography/SubTitle'
import { Paragraph } from '../components/Typography/Paragraph'
import { theme } from '../theme'
import { Context } from '../context/context'
import PageContainer from '../components/PageContainer'

const creditsEnglish = [
  'Jonathan Lee - Head of Projects',
  'Khoi Nguyen - Head Developer',
  'William Nguyen - Product Manager',
  'Joseph Trinh - Head Content Writer',
  'Thu Tran - Resource Manager',
  'Wendy Bui - Lead Translator',
]

const creditsViet = [
  'Jonathan Lee - Người sáng lập',
  'Khoi Nguyen - Trưởng Khối Phát Triển',
  'William Nguyen - Giám đốc sản xuất',
  'Joseph Trinh - Trưởng Khối Phụ Trách Bài Viết',
  'Thu Tran - Quản lý tài nguyên',
  'Wendy Bui - Lead Translator Người dịch chính',
]

const SpacedParagraph = styled(Paragraph)`
  margin-bottom: 2.5%;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    margin-bottom: 10%;
  }
`

const ListContainer = styled.ul`
  padding-top: 1.5%;
  font-size: 20px;
  color: #6c707a;

  > li {
    padding-left: 2.5%;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    font-size: 16px;
    font-weight: 400;
  }
`

export const AboutUs = () => {
  const { showSidebarOnMobile, language, setLanguage } = useContext(Context)
  const { title, about, history } = aboutUsJSON[language]
  const credits = language === ENGLISH ? creditsEnglish : creditsViet

  return (
    <PageContainer
      title={title}
      imgSrc={aboutUs}
      language={language}
      setLanguage={setLanguage}
      showSidebarOnMobile={showSidebarOnMobile}
    >
      <SubTitle>{about.title}</SubTitle>
      <SpacedParagraph>{about.content}</SpacedParagraph>
      <SubTitle>{history.title}</SubTitle>
      <SpacedParagraph>{history.content}</SpacedParagraph>
      <SubTitle>{language === ENGLISH ? 'The Team' : 'Đội ngũ'}</SubTitle>
      <ListContainer>
        {credits.map((name, idx) => (
          <li key={idx}>{name}</li>
        ))}
      </ListContainer>
    </PageContainer>
  )
}
