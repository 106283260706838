import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from './App'

ReactGA.initialize('UA-151018856-1', {
  debug: true
})

ReactDOM.render(<App />, document.getElementById('root'))
