import adhd from './adhd'
import alzheimersDisease from './alzheimersDisease'
import anxiety from './anxiety'
import autism from './autism'
import bipolarDisorder from './bipolarDisorder'
import dementia from './dementia'
import depression from './depression'
import ocd from './ocd'
import parkinsonsDisease from './parkinsonsDisease'
import personalityDisorder from './personalityDisorder'
import ptsd from './ptsd'
import schizophrenia from './schizophrenia'

export default {
  adhd,
  alzheimersDisease,
  anxiety,
  autism,
  bipolarDisorder,
  dementia,
  depression,
  ocd,
  parkinsonsDisease,
  personalityDisorder,
  ptsd,
  schizophrenia
}

// const template = {
//   disorder: '',
//   disorderExpanded: '',
//   content: [
//     {
//       type: 'text',
//       title: '',
//       summary: ''
//     },
//     {
//       type: 'list',
//       title: 'Signs and Symptoms',
//       summary: {
//         list: [
//           {
//             listTitle: '',
//             bullets: []
//           },
//           {
//             listTitle: '',
//             bullets: []
//           }
//         ]
//       }
//     },
//     {
//       type: 'list',
//       title: 'Treatments',
//       summary: {
//         list: [
//           {
//             listTitle: '',
//             bullets: []
//           }
//         ]
//       }
//     },
//     {
//       type: 'list',
//       title: 'References',
//       summary: {
//         list: [
//           {
//             listTitle: '',
//             bullets: []
//           }
//         ]
//       }
//     }
//   ]
// }
