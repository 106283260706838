export const parkinsonsDisease = {
  disorder: 'Parkinson’s Disease',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is Parkinson’s Disease?',
      summary:
        'Parkinson’s Disease is a form of neurodegeneration that causes the death of brain cells in the area that controls movement. This cell death causes noticeable, uncontrollable shaking throughout people’s bodies (tremors) that worsen over time and may interfere with daily activities. Parkinson’s Disease usually affects people over 60 years old.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Tremor in hands, arms, legs, jaw, face',
              'Stiffness in the body',
              'Lowness of movement',
              'Unstable posture',
              'Lack of balance and coordination',
              'Depression',
              'Drastic emotional changes',
              'Difficulty swallowing, chewing',
              'Difficulty speaking',
              'Urinary problems or constipation',
              'Skin problems',
              'Sleep disruptions',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.ninds.nih.gov/Disorders/All-Disorders/Parkinsons-Disease-Information-Page',
              'https://www.nia.nih.gov/health/parkinsons-disease',
              'https://report.nih.gov/nihfactsheets/ViewFactSheet.aspx?csid=109',
            ],
          },
        ],
      },
    },
  ],
}

export const parkinsonsDiseaseViet = {
  disorder: 'Bệnh Parkinson',
  disorderExpanded: '(tiếng Anh: Parkinson’s disease)',
  content: [
    {
      type: 'text',
      title: 'Bệnh Parkinson là gì?',
      summary:
        'Bệnh Parkinson (hay còn gọi là PD) là một rối loạn thoái hoá của hệ thần kinh trung ương làm chết đi  tế bào não trong vùng kiểm soát cử động. Tế bào chết gây ảnh hưởng đến tình trạng cữ động, thăng bằng và kiểm soát cơ của bệnh nhân càng ngày càn tệ hơn theo thời gian và gây cản trở  đến những hoạt động hàng ngày. Bệnh Parkinson thường ảnh hưởng đến người ngoài 60 tuổi.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu chứng:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Cánh tay, bàn tay, chân, hàm, mặt run rẩy',
              'Cứng cơ',
              'Di chuyển chậm chạp',
              'Tư thế bất ổn định',
              'Không thăng bằng ',
              'Trầm cảm',
              'Cảm xúc thay đổi mạnh mẽ',
              'Gặp khó khăn trong việc nhai và nuốt',
              'Gặp khó khăn về ngôn ngữ và khi nói chuyện',
              'Táo bón và vấn đề về tiểu tiện',
              'Vấn đề về da',
              'Gián đoạn giấc ngủ',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.ninds.nih.gov/Disorders/All-Disorders/Parkinsons-Disease-Information-Page',
              'https://www.nia.nih.gov/health/parkinsons-disease',
              'https://report.nih.gov/nihfactsheets/ViewFactSheet.aspx?csid=109',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: parkinsonsDisease,
  vietnamese: parkinsonsDiseaseViet,
}
