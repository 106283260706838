export const adhd = {
  disorder: 'Attention-Deficit/Hyperactivity Disorder',
  disorderExpanded: '(ADHD)',
  content: [
    {
      type: 'text',
      title: 'What is ADHD?',
      summary:
        'ADHD causes children to have excessively high energy levels that reduce their ability to remain still for long periods of time, pay attention, and think before they act. Some children only have problems with one of the behaviors while others have a problem with both inattention and hyperactivity-impulsivity. Even though hyperactivity typically improves as a child grows older, the problems with inattention, disorganization, and lack of impulse control may not. Most children have the combined type of ADHD. It is one of the most common mental disorders in children, affecting about 9.4% in the U.S.',
    },
    {
      type: 'text',
      title: 'Misconceptions about ADHD',
      summary:
        'In preschool, hyperactivity is the most common and obvious symptom of ADHD. Symptoms of inattention, unfocused motor activity, and impulsivity are normal in children. However, for those with ADHD, these symptoms are especially severe, occur more often, and may interfere with their social lives and academic performance. ADHD cannot simply be treated by forcing the child to “calm down” or punishing the child for his or her overactive behavior.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: 'Inattention Behaviors',
            bullets: [
              'Overlooking or missing details ',
              'Making careless mistakes',
              'Having problems sustaining attention during tasks or play',
              'Not seeming to listen when spoken to directly',
              'Not following through on instructions and fails to complete assigned tasks',
              'Starting tasks but becoming sidetracked and quickly losing focus',
              'Difficulty with organizing tasks and activities (keeping track of belongings, messy work, poor time management, failing to meet deadlines)',
              'Avoiding tasks that require sustained mental effort',
              'Being easily distracted by unrelated thoughts or stimuli',
              'Being forgetful in daily activities',
            ],
          },
          {
            listTitle: 'Hyperactivity-Impulsivity Behaviors',
            bullets: [
              'Fidgeting and squirming when sitting down',
              'Running around in inappropriate situations ',
              'Being unable to quietly engage in hobbies or play',
              'Constantly moving',
              'Talking nonstop',
              'Interrupting others when they talk',
              'Having trouble waiting for his or her turn',
              'Interrupting or intruding on others during conversations, games, or activities',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/adhd/what-is-adhd',
              'https://www.nimh.nih.gov/health/topics/attention-deficit-hyperactivity-disorder-adhd/index.shtml',
              'https://report.nih.gov/NIHfactsheets/ViewFactSheet.aspx?csid=25',
              'https://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder-adhd-the-basics/index.shtml',
              'https://www.cdc.gov/ncbddd/adhd/data.html',
            ],
          },
        ],
      },
    },
  ],
}

export const adhdViet = {
  disorder: 'Rối loạn tăng động giảm chú ý',
  disorderExpanded:
    '(tiếng Anh: Attention-deficit hyperactivity disorder- ADHD)',
  content: [
    {
      type: 'text',
      title: 'Rối loạn tăng động giảm chú ý là gì?',
      summary:
        'Rối loạn tăng động giảm chú ý khiến trẻ em có mức năng lượng quá cao, gây giảm đi khả năng ngồi yên một chỗ trong một thời gian dài, chú ý, và suy nghĩ trước khi hành động. Một vài trẻ em chỉ có vấn đề với một trong hai hành vi và một số trẻ khác có vấn đề với cả hai hành động không chú ý và chứng tăng động - bồng bột. Mặc dầu rối loạn tang động thường được cải thiện khi đứa trẻ lớn lên, nhưng vấn đề với không chú ý, vô tổ chức, và không có khả năng kiểm xoát về chứng bồng bột có thể không. Hầu hết trẻ nhỏ sẽ có một dạng kết hợp của rối loạn tang động giãm chú ý. Đây là một trong những loại rối loạn thần kinh phổ biến nhất ở trẻ em, ảnh hưởng khoảng 9.4% ở Mỹ',
    },
    {
      type: 'text',
      title: 'Những quan niệm không đúng về rối loạn tăng động giãm chú ý:',
      summary:
        'Trong trẻ con trước tuổi đến trường, tăng động là dấu hiện nhận biết phổ biến và dễ dàng nhất ở rối loạn tang động giãm chú ý. Dấu hiệu của sự không tập trung, không chú ý, và bất đồng là bình thường ở trẻ em. Tuy nhiên, cho những người bị rối loạn tang động giãm chú ý, những triệu chứng này nghiêm trọng hơn, xảy ra thường xuyên hơn và gây cản trở trọng cuộc sống xã hội và sự học tập của họ. Rối loạn tăng động giãm chú ý không thể đơn giãn là điều trị tập trung để cho đứa trẻ bình tĩnh xuống hoặc trừng phạt đứa trẻ bởi vì những hành động quá nhạnh nhẹn linh hoạt.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu trứng:',
      summary: {
        list: [
          {
            listTitle: 'Hành vi giảm chú ý:',
            bullets: [
              'Thường bỏ qua, không thể tập trung chú ý nhiều vào các chi tiết',
              'Không quan tam đến những say lầm',
              'Thường khó khăn trong việc duy trì khả năng chú ý trong công việc hoặc vui chơi',
              'Thường có vẻ không lắng nghe người khác khi nói chuyện trực tiếp',
              'Thường không tuân theo những hướng dẫn hoặc không thể hoàn tất bài vở ở trường, công việc nhà, hoặc các trách nhiệm nơi làm việc',
              'Có thể bắt đầu các công việc nhưng dễ dàng bị phân tâm và nhanh chóng mất tập trung ',
              'Thường khó khăn trong việc sắp xếp các nhiệm vụ và hoạt động (tự kiểm tra đồ đạc cá nhân, công việc lộn xộn, quản lý thời gian kém, không đáp ứng đúng thời hạn)',
              'Thường né tránh, không thích, hoặc miễn cưỡng tham gia các công việc đòi hỏi sự cố gắng tinh thần trong thời gian dài ',
              'Thường dễ dàng bị phân tâm bởi các kích thích bên ngoài',
              'Thường quên làm các công việc hằng ngày',
            ],
          },
          {
            listTitle: 'Triệu chứng tăng động-bồng bột:',
            bullets: [
              'Tay chân ngọ nguậy, hay vặn vẹo khi ngồi.',
              'Thường chạy nhảy hoặc leo trèo quá mức trong những tình huống không thích hợp ',
              'Thường khó tham gia những trò chơi hoặc hoạt động giải trí yêu cầu sự yên tĩnh và nhẹ nhàng.',
              'Thường luôn di chuyển hoặc hành động',
              'Thường nói quá nhiều.',
              'Thường buột miệng trả lời khi người khác chưa nói xong',
              'Thường khó chờ đợi đến lượt mình.',
              'Thường làm gián đoạn hoặc quấy rầy người khác (xen vào các cuộc nói chuyện hoặc các trò chơi)',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/adhd/what-is-adhd',
              'https://www.nimh.nih.gov/health/topics/attention-deficit-hyperactivity-disorder-adhd/index.shtml',
              'https://report.nih.gov/NIHfactsheets/ViewFactSheet.aspx?csid=25',
              'https://www.nimh.nih.gov/health/publications/attention-deficit-hyperactivity-disorder-adhd-the-basics/index.shtml',
              'https://www.cdc.gov/ncbddd/adhd/data.html',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: adhd,
  vietnamese: adhdViet,
}
