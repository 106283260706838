const english = {
  title: 'Mission',
  mission: {
    title: 'Mission',
    content: `Boat People SOS Center for Community Advancement (BPSOS CCA) (VietMind’s parent organization)’s mission is to improve the lives of Orange County residents through the delivery of effective and sustainable services.
      
      Boat People SOS (BPSOS) is a national Vietnamese-American nonprofit community-based organization with a long record of accomplishment of services since 1980. Our mission is to empower, organize, and equip Vietnamese individuals and communities in their pursuit of liberty and dignity.`,
  },
}

const vietnamese = {
  title: 'Sứ mệnh',
  mission: {
    title: 'Sứ mệnh',
    content: `Sứ mệnh của BPSOS - Center for Community Advancement (BPSOS-CCA) (VietMind’s trụ sở chính)  là cải thiện cuộc sống của cư dân Quận Cam thông qua việc cung cấp các dịch vụ hiệu quả và bền vững.
      
      Boat People SOS (BPSOS) là một tổ chức phi lợi nhuận dành cho cộng đồng người Mỹ gốc Việt và có bề dày thành tích về các dịch vụ kể từ năm 1980. Sứ mệnh của chúng tôi là hỗ trợ, tổ chức và trang bị cho các cá nhân và cộng đồng Việt Nam trong việc theo đuổi tự do và phẩm giá.`,
  },
}
export default { english, vietnamese }
