import styled from 'styled-components'
import { theme } from '../../theme'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  padding: 0 15px;

  @media screen and (max-width: ${theme.breakpoints.md}px) {
    margin: 30px 0;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    margin: 40px 0;
  }
`

export { Content }
