import React, { useContext } from 'react'
import styled from 'styled-components'
import { TextContent } from '../components/Typography/TextContent'
import { renderText } from '../utils/renderText'
import disorders from '../../data/index'
import { theme } from '../theme'
import { Context } from '../context/context'
import { getFormattedIDAndPhotoSrc } from '../utils/getformattedIDAndPhotoSrc'
import PageContainer from '../components/PageContainer'
import MobileOCDContent from '../components/Mobile-OCD-content'
import { OCDTable } from '../components/OCD-Table'
import { List } from '../components/List'

const TextContainer = styled(TextContent)`
  margin-top: 5%;
`

const ListContainer = styled.div`
  margin-top: 3%;
  display: flex;
  flex-direction: column;
`

const TableContainer = styled.div`
  margin-top: 3%;
  display: flex;
  justify-content: center;
`

const IllnessPage = ({
  match: {
    params: { id }
  }
}) => {
  const { showSidebarOnMobile, language, setLanguage } = useContext(Context)
  const { formattedID, photoSrc } = getFormattedIDAndPhotoSrc(id)
  const disorderData = disorders[formattedID][language]
  const { disorder, disorderExpanded, content } = disorderData

  return (
    <PageContainer
      title={disorder}
      imgSrc={photoSrc}
      language={language}
      setLanguage={setLanguage}
      showSidebarOnMobile={showSidebarOnMobile}
      titleExpanded={disorderExpanded}
    >
      <TextContainer>{renderText(content)}</TextContainer>
      {(disorder === 'OCD' || disorder === 'Rối loạn ám ảnh cưỡng chế') && (
        <TableContainer>
          {content.map(illness => {
            if (illness.type !== 'table') return null
            const {
              title,
              summary: { rows }
            } = illness

            const isMobile = window.innerWidth < theme.breakpoints.sm

            return isMobile ? (
              <MobileOCDContent key={title} title={title} rows={rows} />
            ) : (
              <OCDTable key={title} rows={rows} title={title} />
            )
          })}
        </TableContainer>
      )}
      <ListContainer>
        {content.map((illness, index) => {
          if (illness.type !== 'list') return null
          const {
            title,
            summary: { list }
          } = illness

          return <List key={index} title={title} list={list} />
        })}
      </ListContainer>
    </PageContainer>
  )
}

export { IllnessPage }
