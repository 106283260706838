import React, { useContext, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { Home } from './Home'
import { IllnessPage } from './IllnessPage'
import { AboutUs } from './AboutUs'
import { Mission } from './Mission'
import { Context } from '../context/context'

export const Routes = () => {
  const { setSelected } = useContext(Context)

  useEffect(() => {
    const handleOnLoad = () => {
      const { pathname } = window.location
      const pathIndex = pathname.lastIndexOf('/')
      const path = pathname
        .slice(pathIndex + 1)
        .toUpperCase()
        .replace(/-/, ' ')
      setSelected(path)
    }

    window.addEventListener('load', handleOnLoad)
    return () => {
      window.removeEventListener('load', handleOnLoad)
    }
  }, [])

  return (
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route path="/illness/:id" component={IllnessPage} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/mission" component={Mission} />
      <Redirect from="*" to="/home" />
    </Switch>
  )
}
