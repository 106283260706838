import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import { useMediaQuery } from 'react-responsive'

import { MobileBar } from './MobileBar'
import { theme } from '../theme'
import { TopBarNavButton } from './TopBarNavButton'
import { Context } from '../context/context'
import navBarButtonInfo from '../../data/topNavBar'

const Bar = styled(
  posed.nav({
    enter: {
      y: 0
    },
    exit: {
      y: -50
    }
  })
)`
  width: calc(100% - 295px);
  height: 50px;
  background: #fff;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding-right: 35px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  right: 0;

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    width: calc(100% - 195px);
  }

  @media screen and (max-width: 908px) {
    height: 80px;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    width: 100%;
    justify-content: space-evenly;
    padding-left: 15px;
    padding-right: 20px;
  }
`

const Topbar = () => {
  const [scrollPos, setScrollPos] = useState(0)
  const [show, setShow] = useState(true)
  const { selected, setSelected } = useContext(Context)

  useEffect(() => {
    window.onscroll = function() {
      const currScrollPos = window.pageYOffset
      setShow(scrollPos > currScrollPos)
      setScrollPos(currScrollPos)
    }
  })

  const { navBarBtnInfo } = navBarButtonInfo
  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints.md
  })

  return !isMobile ? (
    <Bar pose={show ? 'enter' : 'exit'} role="navigation">
      {navBarBtnInfo.map(({ path, text, translation }) => (
        <TopBarNavButton
          key={text}
          path={path}
          text={text}
          translation={translation}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </Bar>
  ) : (
    <MobileBar />
  )
}

export { Topbar }
