import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Context } from '../context/context'
import { theme } from '../theme'
import { getFormattedIDAndPhotoSrc } from '../utils/getformattedIDAndPhotoSrc'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  box-sizing: border-box;
  margin: 0 10px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    margin: 0 0 10px 0;
    width: 45%;
  }
`

const Image = styled.img`
  width: 100%;
  height: 255px;
  border-radius: 8px;
  background-color: #bdbdbd;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
  object-fit: cover;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    height: 120px;
    margin-bottom: 0;
  }
`

const Text = styled.span`
  display: block;
  color: #575757;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.28px;
`

const TranslatedText = styled.span`
  display: block;
  color: #6e727c;
  font-size: 12px;
  font-weight: 200;
  font-family: 'Quicksand';
  text-transform: uppercase;
  letter-spacing: 1.4px;
`

const IllnessPicker = ({ text, translatedText }) => {
  const { setSelected } = useContext(Context)
  const { photoSrc } = getFormattedIDAndPhotoSrc(text)

  return (
    <Container>
      <Link
        to={`/illness/${text}`}
        onClick={() => setSelected(text.toUpperCase())}
        aria-label={`Select ${text}. Vietnamese translation: ${translatedText}`}
      >
        <Image alt={text} src={photoSrc} />
      </Link>
      <Text>{text}</Text>
      <TranslatedText>{translatedText}</TranslatedText>
    </Container>
  )
}

export { IllnessPicker }
