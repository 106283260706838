export const alzheimersDisease = {
  disorder: 'Alzheimer’s Disease',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is Alzheimer’s Disease?',
      summary:
        'Alzheimer’s disease is the most common form of dementia in which a person’s memory progressively degenerates to the point where his or her ability to learn, reason, make judgements, communicate, and carry out daily activities is impaired. This disease is characterized by a significant loss in brain cells with symptoms typically emerging in peoples’ mid-60s. More than 5.5 million Americans suffer from Alzheimer’s and it is currently ranked as the sixth leading cause of death in the U.S.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Problems with short-term and long-term memory',
              'Problems with decision-making, problem solving, and judgement',
              'Difficulty talking or understanding speech',
              'Difficulty judging shapes, sizes, and distance',
              'Personality and behavior changes',
              'Apathy',
              'Inability to learn new information and cope with new situations',
              'Confusion with time and place (especially in familiar places)',
              'Impulsive behavior',
              'Hallucinations, delusions, and paranoia',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/alzheimers/what-is-alzheimers-disease',
              'https://www.nih.gov/research-training/accelerating-medicines-partnership-amp/alzheimers-disease',
              'https://www.nia.nih.gov/health/alzheimers-disease-fact-sheet',
              "Y. de Saá Guerra, S. Sarmiento Montesdeoca, J.M. García Manso, J.M. Martín González, M. Navarro Valdivielso, D. Rodriguez Ruiz, D. Rodriguez Matoso, M. Quiroga Escudero (2017) Exercise and Alzheimer's: The body as a whole, Revista Andaluza de Medicina del Deporte, Volume 10, Issue 3, 2017, Pages 120-124, ISSN 1888-7546",
              'https://doi.org/10.1016/j.ramd.2015.11.002',
              'http://www.sciencedirect.com/science/article/pii/S1888754616300041',
            ],
          },
        ],
      },
    },
  ],
}

export const alzheimersDiseaseViet = {
  disorder: 'Bệnh Alzheimer',
  disorderExpanded: '(tiếng Anh: Alzheimer’s disease)',
  content: [
    {
      type: 'text',
      title: 'Bệnh Alzheimer là gì?',
      summary:
        'Bệnh Alzheimer là hình thức phổ biến nhất của bệnh sa sút trí tuệ mà bệnh này làm suy thoái khả năng nhận thức một cách từ từ, thường bắt đầu bằng tình trạng mất trí nhớ cho đến một lúc nào đó người bệnh mất khả năng để học hỏi, giảm khả năng phán đoán hay đánh giá kém, khả năng giao tiếp, và khả năng nắm giữ những công việc hằng ngày. Bệnh Alzheimer’s là một bệnh về thể lý não bộ gây nên tình trạng sa sút trí tuệ, hậu quả là trí nhớ, tư duy và hành vi bị suy giãm và thường phát hiện ở người 6o tuổi. Hơn 5.5  triệu người Mỹ đang sống chung với bệnh Alzheimer’s và nó nằm thứ 6 trong nguyên nhân gây chết ở Mỹ.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu chứng:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Có vấn đề với trí nhớ tạm thời và trí nhớ dài hạn',
              'Có vấn đề trong quyết định, giải quyết vấn đề, và phán xét',
              'Những khó khăn về ngôn ngữ chẳng hạn như khó tìm từ thích hợp và hiểu rõ nội dung cuộc nói chuyện',
              'Khó khăn trong nhận xét hình dạng, kích cỡ và khảng cách',
              'Thay đổi về tính cách và hành động',
              'Tính lãnh đạm, tính thờ ơ, sự hờ hững',
              'Không có khả năng tiếp thu thông tin mới và thích nghi với hoàn cảnh mới',
              'Trở nên bối rối và mất phương hướng, ngay cả khi ở những nơi rất quen thuộc',
              'Thái độ bốc đồng',
              'Sự mơ hồ, hoang tưởng trong cuộc nói chuyện hàng ngày',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/alzheimers/what-is-alzheimers-disease',
              'https://www.nih.gov/research-training/accelerating-medicines-partnership-amp/alzheimers-disease',
              'https://www.nia.nih.gov/health/alzheimers-disease-fact-sheet',
              "Y. de Saá Guerra, S. Sarmiento Montesdeoca, J.M. García Manso, J.M. Martín González, M. Navarro Valdivielso, D. Rodriguez Ruiz, D. Rodriguez Matoso, M. Quiroga Escudero (2017) Exercise and Alzheimer's: The body as a whole, Revista Andaluza de Medicina del Deporte, Volume 10, Issue 3, 2017, Pages 120-124, ISSN 1888-7546",
              'https://doi.org/10.1016/j.ramd.2015.11.002',
              'http://www.sciencedirect.com/science/article/pii/S1888754616300041',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: alzheimersDisease,
  vietnamese: alzheimersDiseaseViet,
}
