import React from 'react'
import styled from 'styled-components'

const TableWidthStyles = styled.table`
  width: 75%;
`

const OCDTable = ({ title, rows }) => {
  const tableRowsOfContent = rows.map((row, index) => (
    <tr key={index}>
      {row.map((roww, idx) => (
        <td key={idx}>{roww}</td>
      ))}
    </tr>
  ))

  return (
    <TableWidthStyles>
      <caption>{title}</caption>
      <colgroup span="4" />
      <tbody>{tableRowsOfContent}</tbody>
    </TableWidthStyles>
  )
}
export { OCDTable }
