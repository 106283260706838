import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import Logo from '../../assets/vietmind-logo.png'

import { SidebarLink } from './SidebarLink'
import { theme } from '../theme'
import { Context } from '../context/context'
import { CloseButton, ButtonStyles } from './Burger'

const CloseButtonStyles = styled(ButtonStyles)`
  padding-right: 5%;
  align-self: flex-end;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 295px;
  min-width: 295px;
  min-height: 100vh;
  background: #f8faf8;

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    min-width: 195px;
  }
`

const MobileContainer = styled.div`
  display: ${({ showSidebarOnMobile }) =>
    showSidebarOnMobile ? 'flex' : 'none'};
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  background: #f8faf8;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    align-items: center;
    text-align: center;
  }
`

const Header = styled.div`
  height: 201px;
  width: 100%;
  background: #f5f5f5;
  padding-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    height: 201px;
  }
`

const HeaderText = styled.span`
  font-weight: 400;
  font-size: 20px;
  color: #858a93;
  letter-spacing: 1.31px;
`

const HeaderLogo = styled.img`
  width: 75px;
  height: auto;
  object-fit: contain;
  margin-top: 20px;
`

const MobileListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0;
  margin: 0;
  list-style: none;
`

const MobileListItemStyles = styled.li`
  margin: 0;
`

const LINK_TEXT = [
  {
    main: 'DEPRESSION',
    alt: 'BỆNH TRẦM CẢM'
  },
  {
    main: 'ANXIETY',
    alt: 'BỆNH ÂU LO'
  },
  {
    main: 'PTSD',
    alt: 'BỆNH CĂNG THẲNG DO HẬU CHẤN THƯƠNG'
  },
  {
    main: 'OCD',
    alt: '"BỆNH ÁM ẢNH KHIẾN KHÔNG THỂ CƯỠNG CHẾ CÁC THÔI THÚC"'
  },
  {
    main: 'SCHIZOPHRENIA',
    alt: 'BỆNH TÂM THẦN PHÂN LIỆT'
  },
  {
    main: 'BIPOLAR DISORDER',
    alt: 'BỆNH HƯNG TRẦM CẢM'
  },
  {
    main: 'DEMENTIA',
    alt: 'BỆNH LÚ LẪN (BỆNH LẪN LÃNG)'
  },
  {
    main: "ALZHEIMER'S",
    alt: 'BỆNH ALZHEIMER - THOÁI HÓA TÂM TRÍ DO THOÁI HÓA NÃO BỘ'
  },
  {
    main: 'PERSONALITY DISORDER',
    alt: 'BỆNH RỐI LOẠN NHÂN TÍNH'
  },
  {
    main: 'ADHD',
    alt: 'BỆNH HIẾU ĐỘNG DO KHIẾM KHUYẾT KHẢ NĂNG TẬP TRUNG'
  },
  {
    main: 'AUTISM SPECTRUM DISORDER - ASD',
    alt:
      'BỆNH XẢY RA TRONG GIAI ĐOẠN PHÁT TRIỂN LÀM SUY YẾU KHẢ NӐNG NGÔN NGỮ, BÀY TỎ, VÀ TƯƠNG TÁC'
  },
  {
    main: "PARKINSON'S DISEASE",
    alt: 'BỆNH PARKINSON - RỐI LOẠN VẬN ĐỘNG DO RỐI LOẠN TRUNG KHU THẦN KINH'
  }
]

const Sidebar = () => {
  const {
    selected,
    setSelected,
    showSidebarOnMobile,
    setShowSidebarOnMobile
  } = useContext(Context)

  const [focusedLink, setFocusedLink] = useState('')
  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints.md
  })

  const handleClick = showSidebarOnMobile
    ? disorder => {
        setSelected(disorder)
        setShowSidebarOnMobile(false)
      }
    : disorder => setSelected(disorder)

  const handleHomeOnClick = () => handleClick('HOME')
  const handleCloseOnClick = () => setShowSidebarOnMobile(false)

  return isMobile ? (
    <MobileContainer
      showSidebarOnMobile={showSidebarOnMobile}
      role="region"
      aria-label="disorders menu"
    >
      <Header>
        <CloseButtonStyles aria-label="close menu" onClick={handleCloseOnClick}>
          <CloseButton />
        </CloseButtonStyles>
        <HeaderText>VIETMIND</HeaderText>
        <Link aria-label="go to home" to="/home" onClick={handleHomeOnClick}>
          <HeaderLogo src={Logo} alt="Vietmind logo" />
        </Link>
      </Header>
      <MobileListContainer>
        {LINK_TEXT.map(disorder => (
          <MobileListItemStyles key={disorder.main}>
            <SidebarLink
              main={disorder.main}
              alt={disorder.alt}
              selected={selected === disorder.main}
              onClick={handleClick}
              path={`/illness/${disorder.main
                .toLowerCase()
                .replace(/\s/, '-')}`}
              focusedLink={focusedLink}
              setFocusedLink={setFocusedLink}
            />
          </MobileListItemStyles>
        ))}
      </MobileListContainer>
    </MobileContainer>
  ) : (
    <Container aria-label="left hand navigation" role="region">
      <Header>
        <HeaderText>VIETMIND</HeaderText>
        <Link
          to="/home"
          onClick={handleHomeOnClick}
          aria-label="click to go to home page"
        >
          <HeaderLogo src={Logo} alt="Vietmind logo" />
        </Link>
      </Header>
      {LINK_TEXT.map((disorder, i) => (
        <SidebarLink
          key={disorder.main}
          main={disorder.main}
          alt={disorder.alt}
          selected={selected === disorder.main}
          onClick={handleClick}
          path={`/illness/${disorder.main.toLowerCase().replace(/\s/, '-')}`}
          focusedLink={focusedLink}
          setFocusedLink={setFocusedLink}
        />
      ))}
    </Container>
  )
}

export { Sidebar }
