import styled from 'styled-components'
import { theme } from '../../theme'

const Right50 = styled.div`
  margin-right: 3%;
  width: 50%;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    width: 100%;
    margin-top: 25px;
    margin-right: 0;
  }
`

export { Right50 }
