import styled from 'styled-components'
import { TranslatedTitle } from './TranslatedTitle'
import { theme } from '../../theme'

const StyledTranslatedTitle = styled(TranslatedTitle)`
  margin-left: 10px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    font-size: 18px;
  }
`

export { StyledTranslatedTitle }
