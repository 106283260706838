import styled from 'styled-components'
import { Title } from './Title'
import { theme } from '../../theme'

const StyledTitle = styled(Title)`
  margin-bottom: 5px;
  margin-left: 10px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    font-size: 24px;
    margin-left: 3%;
  }
`

export { StyledTitle }
