import '../assets/normalize.css'
import '../assets/skeleton.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

import ContextProvider from './context/context'
import { Routes } from './pages/Routes'
import { FrontPage } from './pages/FrontPage'
import { Sidebar } from './components/Sidebar'
import { Topbar } from './components/Topbar'

const Container = styled.main`
  display: flex;

  overflow-x: hidden;
  position: relative;
`

const App = () => (
  <ContextProvider>
    <Container>
      <Router>
        <Switch>
          <Route exact path="/" component={FrontPage} />
          <Route
            path="/:id"
            component={() => (
              <>
                <Sidebar />
                <Topbar />
                <Routes />
              </>
            )}
          />
        </Switch>
      </Router>
    </Container>
  </ContextProvider>
)

export default App
