import React, { useEffect } from 'react'
import styled from 'styled-components'
import { VIETNAMESE, ENGLISH } from '../constants/constants'
import { Page } from './Page'
import { theme } from '../theme'
import { StyledTitle } from './Typography/StyledTitle'
import { Content } from './Typography/Content'
import { StyledTranslatedTitle } from '../components/Typography/StyledTranslatedTitle'
import { LanguageSelector } from './LanguageSelector'

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleContainer = styled.div`
  flex-direction: column;
  margin-bottom: 2.5%;
  width: 100%;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    display: flex;
    justify-content: center;
    padding-left: 3%;
  }
`

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Image = styled.img`
  height: 50%;
  width: 50%;
  object-fit: contain;
  border-radius: 8px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    width: 75%;
  }
`

const PageContainer = ({
  title,
  children,
  imgSrc,
  language,
  titleExpanded,
  setLanguage,
  showSidebarOnMobile
}) => {
  const alternateLanguage = language === ENGLISH ? VIETNAMESE : ENGLISH
  const lang = language === ENGLISH ? 'en' : 'vi'

  useEffect(() => {
    if (window.innerWidth < theme.breakpoints.sm) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto'
      })
    }
  })

  return (
    <Page lang={lang} showSidebarOnMobile={showSidebarOnMobile}>
      <TopContainer>
        <TitleContainer>
          <StyledTitle>{title}</StyledTitle>
          {titleExpanded && (
            <StyledTranslatedTitle>{titleExpanded}</StyledTranslatedTitle>
          )}
        </TitleContainer>
        <LanguageSelector
          onClick={() => setLanguage(alternateLanguage)}
          ariaLabel={`translate to ${alternateLanguage}`}
        >
          {alternateLanguage}
        </LanguageSelector>
      </TopContainer>
      <PhotoContainer>
        <Image src={imgSrc} alt="" />
      </PhotoContainer>
      <Content>{children}</Content>
    </Page>
  )
}

export default PageContainer
