import styled from 'styled-components'
import { theme } from '../../theme'

const Left50 = styled.div`
  width: 50%;
  margin-right: 20px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    width: 100%;
    margin-right: 0;
  }
`

export { Left50 }
