import styled from 'styled-components'
import { theme } from '../../theme'

const ListTitle = styled.h3`
  font-weight: 400;
  color: #6c707a;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  display: block;
  margin: 0;
  font-size: 18px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    margin-top: 10px;
    margin-bottom: 6px;
  }
`

export { ListTitle }
