const english = {
  title: 'About Us',
  about: {
    title: 'About',
    content:
      'VietMIND is a platform dedicated to providing mental health education across the Vietnamese-English language barrier. By offering culturally-appropriate descriptions of mental illnesses and updates in mental health news, we hope to improve the knowledge, understanding, and acceptance of mental health in the Vietnamese-American community. Help us spread the word!',
  },

  history: {
    title: 'History',
    content:
      'VietMIND was created by Jonathan Lee to address his frustration with the lack of Vietnamese mental health resources. The idea came to him when he tried to talk to his mom about mental health...and she said that mental illness does not exist. That experience motivated him to create a website that would help the older Vietnamese generation understand mental disorders. Jonathan developed a VietMIND prototype on his own but lacked the resources to develop it into a fully-functioning website until he joined Viet-CARE and BPSOSCCA. With the help of Paul Hoang (Viet-CARE/MFPI), L.C.S.W., and Hang Nguyen (BPSOS CCA) VietMIND came to life as one of BPSOS CCA’s resource projects.',
  },
}

const vietnamese = {
  title: 'Chúng tôi là ai',
  about: {
    title: 'Chúng tôi là ai',
    content:
      'VietMIND là một nền tảng cam kết cung cấp giáo dục về sức khỏe tâm thần xuyên qua rào cản về ngôn ngữ Anh-Việt. Bằng cách đưa ra những miêu tả phù hợp với văn hóa về bệnh thần kinh tâm thần và cập nhật những tin tức về sức khỏe tinh thần, chúng tôi hy vọng được cải thiện kiến thức, sự hiểu biết, và sự chấp nhận về bệnh tâm thần trong cộng đồng người Mỹ gốc Việt. Xin giúp chúng tôi truyền bá thông tin này!',
  },
  history: {
    title: 'Lịch sử',
    content:
      'VietMIND được thành lập bởi Jonathan Lee để bày tỏ sự không hài lòng trong vấn đề thiếu thốn về tài liệu sức khỏe tâm thần bằng Tiếng Việt. Ý định đến với anh ấy khi mà anh ấy cố gắng nói chuyện với mẹ về sức khỏe tâm thần... và bà đã nói rằng sức khỏe tâm thần không tồn tại. Kinh nghiệm đó đã thúc đẩy anh Jonathan tạo ra một trang mạng điện tử nhằm giúp đỡ thế hệ lớn tuổi người Việt hiểu thêm về những rối loạn tâm thần. Jonathan tự mình phát triển một VietMIND nguyên mẫu nhưng vẫn thiếu những tài liệu để phát triển thành một trang mạng điện tử đầy đủ chức năng cho đến khi anh tham gia Viet-C.A.R.E. Với sự giúp đỡ của Paul Hoang (Viet-Care/MFPI), L.C.S.W ( Chuyên Viên Tư Vấn Tâm Lý), và Hang Nguyen (BPSOS CCA), VietMIND đã phát triển thành một trong những dự án tiếp cận cộng đồng của BPSOS CCA.',
  },
}

export default { english, vietnamese }
