export const ptsd = {
  disorder: 'PTSD',
  disorderExpanded: '(Post-Traumatic Stress Disorder)',
  content: [
    {
      type: 'text',
      title: 'What is PTSD',
      summary:
        'Post-traumatic stress disorder (PTSD) is a disorder that develops after very traumatizing experience. During a state of intense fear, the body will make small changes to help it “fight” or “flight” to avoid danger. Typically, these stressful physiological changes will revert to normal some time after the dangerous situation has passed. However, people suffering from PTSD will continue to exhibit high-stress responses even when they are no longer in a dangerous situation. These stress responses might last anywhere from months to years. PTSD affects about 7.7 million American adults per year and can develop at any age',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms (persisting for longer than 1 month)',
      summary: {
        list: [
          {
            listTitle: 'Re-experiencing Symptoms',
            bullets: [
              'Flashbacks-Reliving the traumatic memory over and over',
              'Physical symptoms: racing heart, sweating',
              'Nightmares',
              'Frightening thoughts',
            ],
          },
          {
            listTitle: 'Avoidance Symptoms',
            bullets: [
              'Staying away from locations, objects, or anything that may remind the victim of the traumatic experience',
              'Avoiding thoughts or feelings that are associated with the traumatic experience',
            ],
          },
          {
            listTitle: 'Arousal and reactive symptoms',
            bullets: [
              'Being easily startled',
              'Constantly tense or “on edge”',
              'Difficulty sleeping',
              'Angry outbursts',
            ],
          },
          {
            listTitle: 'Cognitive and mood symptoms',
            bullets: [
              'Difficulty remembering key details of the traumatic event',
              'Negative thoughts',
              'Distorted feelings like guilt or blame',
              'Loss of interest in fun activities',
            ],
          },
          {
            listTitle: 'Symptoms more common in children',
            bullets: [
              'Wetting the bed after having learned to use the toilet',
              'Forgetting how to or being unable to talk',
              'Acting out the scary event',
              'Being unusually clingy with a parent or adul',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Treatment',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Medications: Antidepressants [Serotonin reuptake inhibitors (SRIs), selective serotonin reuptake inhibitors (SSRIs)]',
              'Psychotherapy: Cognitive behavioral therapy',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.nimh.nih.gov/health/topics/post-traumatic-stress-disorder-ptsd/index.shtml',
              'https://report.nih.gov/NIHfactsheets/ViewFactSheet.aspx?csid=58 ',
              'https://www.psychiatry.org/patients-families/ptsd/what-is-ptsd',
            ],
          },
        ],
      },
    },
  ],
}

export const ptsdViet = {
  disorder: 'Rối loạn căng thẳng hậu chấn thương hay Hậu chấn tâm lý ',
  disorderExpanded: '(tiếng Anh: Posttraumatic Stress Disorder- PTSD)',
  content: [
    {
      type: 'text',
      title: 'Rối loạn căng thẳng hậu chấn thương hay Hậu chấn tâm lý là gì?',
      summary:
        'Rối loạn căng thẳng hậu chấn thương hay Hậu chấn tâm lý là một rối loạn tâm lý, tổn thương về mặt tinh thần, biểu hiện bằng các triệu chứng lo âu rõ rệt sau khi phải đương đầu với sự kiện gây tổn thương và vẫn tiếp tục kéo dài sau đó khi sự kiện đã kết thúc từ lâu. Bệnh hay gặp ở những người từng trải qua các biến cố gây ảnh hưởng nghiêm trọng đến sức khỏe tinh thần hoặc/và thể chất. Những triệu trứng lo âu có thể tồn tại từ nhiều tháng cho đến vài năm. Rối loạn căng thẳng hậu chấn thương hay Hậu chấn tâm lý ảnh hưởng khoảng 7.7 triệu người trưởng thành ở Mỹ mỗi năm và có thể xảy ra ở bất cứ độ tuổi nào.',
    },
    {
      type: 'list',
      title:
        'Các triệu chứng của rối loạn căng thẳng hậu chấn thương: (Thời gian kéo dài của rối loạn này trên 1 tháng)',
      summary: {
        list: [
          {
            listTitle: 'Trải nghiệm lại các sự kiện gây chấn thương:',
            bullets: [
              'Người bệnh có những hồi tưởng khó cưỡng lại được về các biến cố gây chấn thương',
              'Triệu trứng trong cơ thể: tim đập nhanh, toát mồ hôi',
              'Ác mộng',
              'Suy nghĩ hoảng sợ',
            ],
          },
          {
            listTitle:
              'Lẩn tránh các kích thích liên quan đến biến cố sang chấn/gây chấn thương:',
            bullets: [
              'Tránh xa bất cứ điều gì gợi nhắc lại đến sự kiện sang chấn/ gây chấn thương',
              'Cố gắng không suy nghĩ hay đề cập những vấn đề liên quan đến biến cố',
            ],
          },
          {
            listTitle: 'Nhạy cảm quá mức:',
            bullets: [
              'Trở nên nhạy cảm hơn với các kích thích',
              'Liên tục căng thẳng, dễ giật mình',
              'Khó đi vào giấc ngủ và dễ mất ngủ',
              'Dể dàng nổi giận',
            ],
          },
          {
            listTitle: 'Nhận thức và tâm trạng:',
            bullets: [
              'Khó khăn trong việc nhớ lại những chi tiết của sự kiện sang chấn/gây chấn thương',
              'Suy nghĩ tiêu cực',
              'Mặc cảm tội lỗi',
              'Mất đi niềm thích thú trong những hoạt động vui chơi',
            ],
          },
          {
            listTitle: 'Các triệu chứng phổ biến ở trẻ em:',
            bullets: [
              'Bệnh đái dầm sau khi đã học cách sử dụng toilet',
              'Quên hoặc không biết cách giao tiếp bằng ngôn ngữ',
              'Hành động kì lạ trong những sự kiện hoảng sợ',
              'Bám theo cha mẹ hoặc người lớn tuổi một cách bất thường',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Điều trị',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Thuốc: chống trầm cảm [(Serotonin Reuptake inhibitors (SRIs), selective serotonin reuptake inhibitors (SSRIs)]',
              'Tâm lý trị liệu: Phương pháp điều trị nhận thức cố gắng điều chỉnh hành vi',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.nimh.nih.gov/health/topics/post-traumatic-stress-disorder-ptsd/index.shtml',
              'https://report.nih.gov/NIHfactsheets/ViewFactSheet.aspx?csid=58',
              'https://www.psychiatry.org/patients-families/ptsd/what-is-ptsd',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: ptsd,
  vietnamese: ptsdViet,
}
