import React from 'react'
import styled from 'styled-components'
import { SubTitle } from '../components/Typography/SubTitle'
import { Paragraph } from '../components/Typography/Paragraph'
import { Left50 } from '../components/Typography/Left50'
import { Right50 } from '../components/Typography/Right50'

const Center100 = styled.div`
  width: 100%;
  flex-direction: column;
`

const renderTextBlob = (Component, { title, summary }) => (
  <React.Fragment key={title}>
    <Component>
      <SubTitle>{title}</SubTitle>
      <Paragraph>{summary}</Paragraph>
    </Component>
  </React.Fragment>
)

export const renderText = content => {
  const textContent = content.filter(illness => illness.type === 'text')
  const everyThingElse = content.filter(illness => illness.type !== 'text')

  const textContentRenderFn =
    textContent.length > 1
      ? textContent.map((illness, idx) => {
          const isEven = idx % 2 === 0
          const Component = isEven ? Left50 : Right50

          return renderTextBlob(Component, illness)
        })
      : textContent.map(illness => renderTextBlob(Center100, illness))
  const everythingElseRenderFn = everyThingElse.map(() => {
    return null
  })

  return [...textContentRenderFn, ...everythingElseRenderFn]
}

// export const renderText = content => {
//   console.log(
//     content.map((illness, idx) => {
//       if (illness.type !== 'text') return null

//       const isEven = idx % 2 === 0
//       const Component = isEven ? Left50 : Right50

//       return renderTextBlob(Component, illness)
//     })
//   )
//   return content.map((illness, idx) => {
//     if (illness.type !== 'text') return null

//     const isEven = idx % 2 === 0
//     const Component = isEven ? Left50 : Right50

//     return renderTextBlob(Component, illness)
//   })
// }
