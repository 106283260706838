import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import background from '../../assets/front-page.png'
import posed, { PoseGroup } from 'react-pose'
import { theme } from '../theme'

const Container = styled.main`
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`

const Background = styled(
  posed.div({
    initial: {
      top: '-50%'
    },
    enter: {
      top: 0,
      delay: 200,
      transition: {
        duration: 2500,
        ease: 'easeOut'
      }
    }
  })
)`
  position: absolute;
  min-height: 150%;
  min-width: 100%;
  width: 100%;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const BackgroundCover = styled.div`
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  background-color: ${props =>
    props.scrolled ? 'rgba(0, 0, 0, 0.25)' : 'transparent'};

  transition: background-color 500ms ease-in-out;
`

const TitleContainer = styled.div`
  position: relative;
  transition: opacity 500ms ease-in-out 500ms;
  opacity: ${props => (props.scrolled ? '1' : '0')};
  text-align: center;

  margin: auto;
  top: 10%;
  width: 50%;
`

const Title = styled.h1`
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  text-align: left;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    font-size: 3rem;
  }
`

const Button = styled.button`
  border-color: white;
  color: white;
  margin-top: 50px;
  margin-left: 75%;

  transition: all 200ms ease-in-out;

  & > a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    border-color: rgba(225, 225, 225, 1);
    color: rgba(225, 225, 225, 1);

    & > a {
      color: rgba(225, 225, 225, 1);
    }
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    margin-left: 0;
  }
`

const FrontPage = () => {
  const [scrolled, setScrolled] = useState(false)
  const handleAnimationEnd = () => setScrolled(true)

  return (
    <Container>
      <PoseGroup preEnterPose="initial" animateOnMount>
        <Background key="background" onPoseComplete={handleAnimationEnd} />
      </PoseGroup>

      <BackgroundCover scrolled={scrolled} />

      <TitleContainer scrolled={scrolled}>
        <Title>Have You Ever Thought About Mental Illness?</Title>

        <Link to="/home">
          <Button>Click to Enter</Button>
        </Link>
      </TitleContainer>
    </Container>
  )
}

export { FrontPage }
