import styled from 'styled-components'

const TranslatedTitle = styled.h1`
  font-size: 20px;
  color: #6c707a;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-family: 'Quicksand';
  display: block;
  margin: 0;
`
export { TranslatedTitle }
