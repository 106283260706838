import React, { useState, createContext } from 'react'
import { ENGLISH } from '../constants/constants'

export const Context = createContext()

const ContextProvider = ({ children }) => {
  const [selected, setSelected] = useState('')
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false)
  const [language, setLanguage] = useState(ENGLISH)

  return (
    <Context.Provider
      value={{
        selected,
        setSelected,
        showSidebarOnMobile,
        setShowSidebarOnMobile,
        language,
        setLanguage
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ContextProvider
