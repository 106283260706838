import styled from 'styled-components'
import { theme } from '../../theme'

const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #222;
  margin-top: 10px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    margin: 20px 0;
    flex-wrap: wrap;
  }
`

export { TextContent }
