import styled from 'styled-components'
import { theme } from '../theme'

const Page = styled.section`
  display: ${({ showSidebarOnMobile }) =>
    showSidebarOnMobile ? 'none' : 'flex'};
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin: 80px 3% 0 3%;
  background-color: white;
  font-family: ${props => props.lang === 'vi' && 'Quicksand'};

  @media screen and (max-width: 908px) {
    margin-top: 110px;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    word-break: break-word;
  }
`

export { Page }
