import React, { useState } from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import { theme } from '../theme'
import { Link } from 'react-router-dom'

const Container = styled(
  posed.div({
    show: { y: 0, opacity: 1 },
    hide: { y: 20, opacity: 0 }
  })
)`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    & span {
      color: #db0000;
    }
  }
`

const Selector = styled(
  posed.div({
    enter: {
      minWidth: '14px',
      minHeight: '14px',
      opacity: 1
    },
    exit: {
      minWidth: '0px',
      minHeight: '0px',
      opacity: 0
    }
  })
)`
  border-radius: 9999px;
  background: #fc385a;
  box-shadow: 0 2px 4px 0px rgba(193, 37, 65, 0.5);
  margin-right: 15px;
  margin-top: -7px;
`

// Link should be for the container for bigger surface area
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  text-decoration: none;
`

const Main = styled.span`
  font-weight: ${props => (props.selected ? 800 : 300)};
  font-size: 14px;
  color: ${props => (props.selected ? '#DB0000' : '#6C707A')};
  letter-spacing: 1.31px;
  margin-bottom: 0;

  transition: color 200ms ease-in-out;

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    font-size: 12px;
  }
`

const Alt = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: ${props => (props.selected ? 600 : 200)};
  font-size: 11px;
  color: ${props => (props.selected ? '#DB0000' : '#6E727C')};
  letter-spacing: 1.12px;
  margin-bottom: 0;

  transition: color 200ms ease-in-out;

  @media screen and (max-width: ${theme.breakpoints.lg}px) {
    font-size: 10px;
  }
`

const SidebarLink = ({
  alt,
  main,
  onClick,
  selected,
  path,
  focusedLink,
  setFocusedLink
}) => {
  const [hovered, setHovered] = useState(false)

  const handleMouseOver = () => setHovered(true)
  const handleMouseOut = () => setHovered(false)
  const handleClick = () => onClick(main)
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onClick(main)
    }

    if (
      (e.key === 'Tab' && focusedLink === "PARKINSON'S DISEASE") ||
      (e.shiftKey && e.key === 'Tab' && focusedLink === 'DEPRESSION')
    ) {
      setFocusedLink('')
    }
  }

  const handleFocus = () => setFocusedLink(main)
  const isFocused = main === focusedLink

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={path}
      aria-label={`Select disorder: ${main}. Vietnamese translation: ${alt}`}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
    >
      <Container
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <TextContainer>
          <Main selected={selected}>{main}</Main>
          <Alt selected={selected}>{alt}</Alt>
        </TextContainer>
      </Container>
    </Link>
  )
}

export { SidebarLink }
