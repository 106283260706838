import styled from 'styled-components'

const Title = styled.h1`
  font-weight: 500;
  font-size: 28px;
  color: #6c707a;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  display: block;
  margin: 0;
`
export { Title }
