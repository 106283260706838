export const schizophrenia = {
  disorder: 'Schizophrenia',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is schizophrenia?',
      summary:
        'Schizophrenia is a mental disorder that causes individuals to lose touch with reality. It can be characterized by episodes in which real and unreal experiences become indistinguishable. This distortion of reality may cause people with schizophrenia to become paranoid, fearful, withdrawn, and agitated; which then prevents them from being able to hold a job or care for themselves. Schizophrenia affects about 2.4 million Americans, with the onset of symptoms usually occurring in their late teenage years to early 30s.',
    },
    {
      type: 'text',
      title: 'Misconceptions about schizophrenia?',
      summary:
        'Schizophrenia is not a condition in which people have split personalities or multiple-personalities. Split- and multiple- personalities are characteristic of “personality disorders”, which is a separate category of mental disorders. \n Most people with schizophrenia are also not dangerous or violent. The ideas they express may be nonsensical and unsettling, but they will not necessarily follow up with harmful actions.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle:
              'Positive psychotic symptoms (Sensing and believing in things that are not there)',
            bullets: [
              'Hallucinations (such as seeing people that aren’t there and hearing voices) ',
              'Paranoid delusions (thinks someone is out to get them)',
              'Exaggerated or distorted perceptions, beliefs and behaviors',
            ],
          },
          {
            listTitle:
              'Negative symptoms (Disruptions to normal emotions and behaviors)',
            bullets: [
              '“Flat affect” (reduced expression of emotions through voice tone or facial expressions)',
              'Reduced feelings of pleasure from enjoyable activities',
              'Difficulty initiating and sustaining activities',
              'Decreased ability to speak',
            ],
          },
          {
            listTitle:
              'Cognitive symptoms (changes to memory or other aspects of thinking)',
            bullets: [
              'Poor “executive functioning” (ability to understand information and use it for decision-making)',
              'Trouble focusing or paying attention',
              'Poor “working memory” (ability to use information immediately after learning it)',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/schizophrenia/what-is-schizophrenia',
              'https://www.nimh.nih.gov/health/topics/schizophrenia/index.shtml',
              'https://report.nih.gov/nihfactsheets/ViewFactSheet.aspx?csid=67&key=S',
            ],
          },
        ],
      },
    },
  ],
}

export const schizophreniaViet = {
  disorder: 'Tâm thần phân liệt',
  disorderExpanded: '(tiếng Anh: Schizophrenia)',
  content: [
    {
      type: 'text',
      title: 'Tâm thần phân liệt là gì?',
      summary:
        'Tâm thần phân liệt là một rối loạn thần kinh khiến người bệnh có những ý nghĩ sai lệch, dị kỳ, không phù hợp với thực tế. Người bệnh không thể định được những trải nghiệm thật hay không thật. Chỉ những tác động nhỏ đủ khiến cho người bệnh mất đi ý thức, trở nên lo sợ, hoảng loạn, giận dữ, kích động điều mà ảnh hưởng lớn đến công việc cũng như kỹ năng chăm sóc bản thân. Tâm thần phân liệt ảnh hưởng 2.4  triệu ngưởi ở Mỹ, và thường được phát hiện ở những năm cuối tuổi thiếu niên đến đầu những năm 30 tuổi.',
    },
    {
      type: 'text',
      title: 'Những quan niệm không đúng về bệnh tâm thần phân liệt:',
      summary:
        'Tâm thần phân liệt  không phải là một tình trạng mà người bệnh có  sự phân chia tính cách hoặc đa tính cách. Sự phân tách tính cách hoặc đa tính cách phản ánh từ biểu hiện của bệnh rối loạn nhân cách, 1 loại bệnh khác của nhóm bệnh thần kinh. Hấu hết người bệnh tâm thần phân liệt không gây nguy hiểm hoặc hung bạo. Những ý kiến mà họ thể hiện có thể không hợp lý hoặc không sắp xếp, nhưng họ sẽ không thực hiện những hành động gây nguy hại đến người khác.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu triệu chứng:',
      summary: {
        list: [
          {
            listTitle:
              'Triệu chứng dương tính (Cảm giác hoặc tin rằng mọi thứ không hiện diện):',
            bullets: [
              'Hoang tưởng (như là thấy người ta có mặt nhưng thật sự họ không có ở đó, hoặc là nghe thấy tiếng nói trong đầu',
              'Ảo giác: nghĩ rằng một người nào đó muốn tạo rắc rối cho họ',
              'Phóng đại hoặc là xuyên tạc về nhận thức, đức tin hoặc hành động',
            ],
          },
          {
            listTitle:
              'Triệu chứng âm tính (Sự khuyết thiếu những phản ứng đặc thù về cảm xúc hay hành động):',
            bullets: [
              'Người bệnh không thể hiện bất kỳ cảm xúc nào (Họ không vui cũng chẳng buồn, và có vẻ thờ ơ với môi trường chung quanh. Gương mặt họ vô cảm và lãnh đạm. Giọng nói',
              'không có nhịp điệu lên xuống)',
              'Một dạng khác của sự thiếu hụt cảm xúc chính là không thể cảm nhận được khoái lạc.',
              'Khó khăn trong việc khởi đầu và duy trì hoạt động',
              'Người bệnh giảm thiểu số lượng ngôn ngữ quá nhiều',
            ],
          },
          {
            listTitle:
              'Triệu trứng về nhận thức (thay đổi trí nhớ và những khía cạnh khác của suy nghĩ):',
            bullets: [
              'Khả năng nhận thức kém (khả năng nhận thức thông tin và sử dụng nó để tạo ra quyết định)',
              'Khó tập trung và chú ý',
              'Trí nhớ tệ (khả năng sử dụng thông tin ngay lập tức sau khi học được)',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/schizophrenia/what-is-schizophrenia',
              'https://www.nimh.nih.gov/health/topics/schizophrenia/index.shtml',
              'https://report.nih.gov/nihfactsheets/ViewFactSheet.aspx?csid=67&key=S',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: schizophrenia,
  vietnamese: schizophreniaViet,
}
