import React, { useContext } from 'react'
import styled from 'styled-components'

import { VietnameseText } from './Typography/VietnameseText'
import navBarButtonInfo from '../../data/topNavBar'
import { TopBarNavButton } from './TopBarNavButton'
import { Context } from '../context/context'

const StyledMenu = styled.nav`
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 70px;
  right: 0;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(0)' : 'translateY(-100%)'};
  width: 175px;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.15);
`

const DisorderButton = styled.button`
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
  color: ${props => (props.selected ? '#DB0000' : '#6C707A')};
  font-weight: ${props => (props.selected ? 'bold' : 200)};
  line-height: 1.6;

  &:hover {
    color: #db0000;
    font-weight: bold;
  }

  height: 50px;
  margin: 0;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.3);
`

export const Menu = ({ isOpen, setIsOpen }) => {
  const { selected, setSelected, setShowSidebarOnMobile } = useContext(Context)
  const { navBarBtnInfo } = navBarButtonInfo

  const menuButtons = navBarBtnInfo.map(({ path, text, translation }) => (
    <TopBarNavButton
      key={text}
      path={path}
      text={text}
      translation={translation}
      selected={selected}
      setSelected={setSelected}
      setIsOpen={setIsOpen}
    />
  ))

  menuButtons.splice(
    1,
    0,
    <DisorderButton
      key="disorders"
      onClick={() => {
        setShowSidebarOnMobile(true)
        setSelected('disorders')
      }}
      selected={selected === 'disorders'}
      aria-label={`click to open disorders menu. Vietnamese translation: Rối loạn tâm thần`}
    >
      <span>
        Disorders
        <br />
        <VietnameseText>Rối loạn tâm thần</VietnameseText>
      </span>
    </DisorderButton>
  )

  return (
    <StyledMenu aria-disabled={!isOpen} isOpen={isOpen}>
      {menuButtons}
    </StyledMenu>
  )
}
