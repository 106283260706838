import React from 'react'
import styled from 'styled-components'

export const ButtonStyles = styled.button`
  border: none;
  margin: 0;
  padding: 0;
`

const BurgerButton = () => (
  <svg
    id='i-menu'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='36'
    height='36'
    fill='none'
    stroke='#858a93'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
  >
    <path d='M4 8 L28 8 M4 16 L28 16 M4 24 L28 24' />
  </svg>
)

export const CloseButton = () => (
  <svg
    id='i-close'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    width='32'
    height='32'
    fill='none'
    stroke='#858a93'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
  >
    <path d='M2 30 L30 2 M30 30 L2 2' />
  </svg>
)

export const Burger = ({ isOpen, setIsOpen }) => {
  const ariaLabel = isOpen ? 'close' : 'open navigation menu'
  return (
    <ButtonStyles aria-label={ariaLabel} onClick={() => setIsOpen(!isOpen)}>
      {isOpen ? <CloseButton /> : <BurgerButton />}
    </ButtonStyles>
  )
}
