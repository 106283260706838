import React, { useContext } from 'react'
import styled from 'styled-components'
import { Page } from '../components/Page'
import { SubTitle } from '../components/Typography/SubTitle'
import { TranslatedSubTitle } from '../components/Typography/TranslatedSubTitle'
import { Paragraph } from '../components/Typography/Paragraph'
import { TranslatedParagraph } from '../components/Typography/TranslatedParagraph'
import { IllnessPicker } from '../components/IllnessPicker'
import { theme } from '../theme'
import { Context } from '../context/context'
import { StyledTitle } from '../components/Typography/StyledTitle'
import { StyledTranslatedTitle } from '../components/Typography/StyledTranslatedTitle'
import { Content } from '../components/Typography/Content'
import { TextContent } from '../components/Typography/TextContent'
import { Left50 } from '../components/Typography/Left50'
import { Right50 } from '../components/Typography/Right50'

const IllnessContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
`

const Home = () => {
  const { showSidebarOnMobile } = useContext(Context)
  return (
    <Page showSidebarOnMobile={showSidebarOnMobile}>
      <StyledTitle>MOST COMMON MENTAL ILLNESSES</StyledTitle>
      <StyledTranslatedTitle>
        Bệnh tâm thần phổ biến nhiều nhất
      </StyledTranslatedTitle>

      <Content>
        <IllnessContainer>
          <IllnessPicker text="depression" translatedText="BỆNH TRẦM CẢM" />
          <IllnessPicker text="anxiety" translatedText="BỆNH ÂU LO" />
          <IllnessPicker
            text="ptsd"
            translatedText="BỆNH KHỦNG HOẢNG SAU TRẤN THƯƠNG"
          />
          <IllnessPicker
            text="ocd"
            translatedText="BỆNH RỐI LOẠN ÁM ẢNH CƯỠNG CHẾ"
          />
        </IllnessContainer>

        <TextContent>
          <Left50>
            <SubTitle style={{ marginBottom: '25px' }}>
              What is mental illness?
            </SubTitle>
            <Paragraph>
              A mental illness is a condition that affects a person's thinking,
              feeling, or mood. Such conditions may affect someone's ability to
              relate to others and function each day. Each person will have
              different experiences, even if two people have the same diagnosis.
            </Paragraph>
          </Left50>

          <Right50>
            <TranslatedSubTitle style={{ marginBottom: '25px' }}>
              BỆNH TÂM THẦN LÀ GÌ?
            </TranslatedSubTitle>
            <TranslatedParagraph>
              Bệnh tâm thần là một bệnh ảnh hưởng tới một người của suy nghĩ,
              cảm giác và cảm xúc. điều kiện như vậy có thể ảnh hưởng đến khả
              năng của một ai đó để liên hệ với những người khác và hoạt động
              mỗi ngày. Mỗi người sẽ có những trải nghiệm khác nhau, thậm chí
              những người có các chẩn đoán.
            </TranslatedParagraph>
          </Right50>
        </TextContent>
      </Content>
    </Page>
  )
}
export { Home }
