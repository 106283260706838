import styled from 'styled-components'
import { Link as Link_ } from 'react-router-dom'

const Link = styled(Link_)`
  min-height: 0;
  line-height: normal;
  color: #bdbdbd;
  font-size: 10px;
  font-weight: 200;
  text-decoration: none;
  letter-spacing: 1.15px;

  &:visited,
  &:active {
    text-decoration: none;
  }

  &:hover {
    color: #bdbdbd;
  }
`

export { Link }
