import React, { useContext } from 'react'
import styled from 'styled-components'
import { SubTitle } from '../components/Typography/SubTitle'
import missionJSON from '../../data/mission'
import { Context } from '../context/context'
import { TextContent } from '../components/Typography/TextContent'
import { Li } from '../components/Typography/ListItem'
import PageContainer from '../components/PageContainer'
import MissionPhoto from '../../assets/compressed/mission.jpg'

const StyledSubTitle = styled(SubTitle)`
  margin-top: 25px;
`

export const Mission = () => {
  const { showSidebarOnMobile, language, setLanguage } = useContext(Context)
  const { title, mission, vision, objectives } = missionJSON[language]

  return (
    <PageContainer
      title={title}
      imgSrc={MissionPhoto}
      language={language}
      setLanguage={setLanguage}
      showSidebarOnMobile={showSidebarOnMobile}
    >
      <StyledSubTitle>{mission.title}</StyledSubTitle>
      <TextContent>{mission.content}</TextContent>
    </PageContainer>
  )
}
