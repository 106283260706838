export const bipolarDisorder = {
  disorder: 'Bipolar Disorder',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is bipolar disorder?',
      summary:
        'Bipolar disorder is a group of mental disorders characterized by extreme shifts in emotional states, from a “manic” state to a polar-opposite, “depressive” state. The “manic” state is characterized by excessive joyfulness and excitement, while the “depressive” state is characterized by extreme sadness and hopelessness. There is also a “mixed” state that includes parts of both the manic and depressive states. This disorder often emerges in older teenagers and young adults, with at least half of the cases starting before age 25, and about 15%-17% of all cases resulting in suicide. The four basic types of bipolar disorder include: bipolar I disorder, bipolar II disorder, bipolar disorder not otherwise specified, and cyclothymic disorder.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: 'Manic State Characteristics',
            bullets: [
              'Lots of energy',
              'High activity levels',
              'Feeling “jumpy” or “wired”',
              'Difficulty sleeping',
              'Scattered, fast thoughts ',
              'Agitated, irritable, or “touchy”',
              'Engage in risky activity',
            ],
          },
          {
            listTitle: 'Depressive State Characteristics',
            bullets: [
              'Feel very sad, “down”, empty, or hopeless',
              'Low energy levels',
              'Difficulty sleeping (includes oversleeping)',
              'Not getting enjoyment out of anything',
              'Difficulty concentrating',
              'Forgetfulness ',
              'Suicidal thoughts',
              'Changes to appetite (Undereating/Overeating)',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Treatment',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Medication: Mood stabilizers, atypical antipsychotics, antidepressants',
              'Therapy: Cognitive behavioral therapy, family-focused therapy, interpersonal and social rhythm therapy, psychoeducation',
              'Brain stimulation therapies: Electroconvulsive therapy',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/bipolar-disorders/what-are-bipolar-disorders',
              'https://www.nimh.nih.gov/health/topics/bipolar-disorder/index.shtml',
              'https://www.treatmentadvocacycenter.org/evidence-and-research/learn-more-about/463-bipolar-disorder-fact-sheet',
            ],
          },
        ],
      },
    },
  ],
}

export const bipolarDisorderViet = {
  disorder: 'Rối loạn cảm xúc lưỡng cực',
  disorderExpanded: '(tiếng anh: Bipolar disorder)',
  content: [
    {
      type: 'text',
      title: 'Rối loạn cảm xúc lưỡng cực là gì?',
      summary:
        'Rối loạn cảm xúc lưỡng cực là một chứng bệnh rối loạn tâm thần gây ra sự biến đổi cảm xúc không ổn định. Người bệnh chuyển từ cảm xúc hưng phấn (hưng cảm) sang cảm xúc ức chế (trầm cảm). Bệnh có tính chất chu kỳ xen kẽ giữa hưng phấn và ức chế. Rối loạn này thường xuất hiện ở thời kỳ cuối thanh thiếu niên và thanh niên với ít nhất phân nữa người bệnh bắt đầu trước tuổi 25 và khoảng 15 đến 17 phần trăm trường hợp dẫn đến tự tử. Bốn loại của rối loạn cảm xúc lưỡng cực bao gồm: rối loạn cảm xúc lưỡng cực I, rối loạn cảm xúc lưỡng cực II, rối loạn cảm xúc lưỡng cực không biểu hiện rõ ràng, Rối loạn cyclothymic dạng hưng-trầm cảm nhẹ (dưới ngưỡng rối loạn cảm xúc lưỡng cực)',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu trứng:',
      summary: {
        list: [
          {
            listTitle: 'Hưng cảm:',
            bullets: [
              'Dư năng lượng',
              'Tăng động ',
              'Cảm giác bay bổng',
              'Khó ngủ',
              'Nảy ra nhiều ý tưởng, suy nghĩ dồn dập, không kiểm soát',
              'Kích động, dễ nỗi cáu, hay là dễ giận dỗi',
              'Thực hiện những việc mạo hiểm',
            ],
          },
          {
            listTitle: 'Trầm cảm:',
            bullets: [
              'Cảm giác buồn bã, bi quan, trống rỗng, vô vọng',
              'Kém năng lượng',
              'Khó ngủ (bao gồm ngủ quá nhiều)',
              'Cảm giác vô vị',
              'Khó tập trung',
              'Hay quên',
              'Ý nghĩ tự tử',
              'Thay đổi cảm giác ăn uống (biếng ăn hay ăn nhiều quá)',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Điều trị:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Thuốc: ổn định khí sắc, chống loạn thần, chống trầm cảm',
              'Trị liệu: Phương pháp chữa trị nhận thức cố gắng điều chỉnh hành vi (CBT), điều trị tập trung vào gia đình, cá nhân, xã hội và tâm lý',
              'Điều trị kích thích trí não: điều trị bằng choáng điện',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/bipolar-disorders/what-are-bipolar-disorders',
              'https://www.nimh.nih.gov/health/topics/bipolar-disorder/index.shtml',
              'https://www.treatmentadvocacycenter.org/evidence-and-research/learn-more-about/463-bipolar-disorder-fact-sheet',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: bipolarDisorder,
  vietnamese: bipolarDisorderViet,
}
