export const ocd = {
  disorder: 'OCD',
  disorderExpanded: '(Obsessive-Compulsive Disorder)',
  content: [
    {
      type: 'text',
      title: 'What is OCD?',
      summary:
        'In OCD, obsessions are intrusive and recurring thoughts, urges, or impulses accompanied by feelings of anxiety or distress. These feelings of anxiety or distress can only be relieved by compulsions; actions that must be performed to suppress the obsessions. Compulsions are often repetitive behaviors that are unrelated to the obsessions or are clearly excessive. OCD affects about 2.2 million American adults, and often begins in childhood.',
    },
    {
      type: 'table',
      title: 'Categories and Examples of OCD',
      summary: {
        rows: [
          ['Category', 'Description', 'Obsession', 'Compulsion'],
          [
            'Washers',
            'Fear of contamination',
            'I am afraid that germs on my skin will infect me.',
            'I wash my hands 5 times in a row to make sure that they are completely clean.',
          ],
          [
            'Checkers',
            'Repeatedly check items that can lead to a dangerous situation.',
            'I am worried that I will leave my front door unlocked.',
            'I continually return to my house throughout the day to make sure that the door is still locked.',
          ],
          [
            'Doubters and sinners',
            'If their actions aren’t perfect something terrible beyond their control will happen',
            'I accidentally said a mean word to someone else',
            'I am going to be punished by a divine power.',
          ],
          [
            'Counters and arrangers',
            'Obsession with order and symmetry. Includes superstitions about numbers, colors, or arrangements.',
            'I don’t like the number 23.',
            'If I count 23 items, I must keep counting other objects to avoid the number',
          ],
          [
            'Hoarders',
            'Fear of a bad outcome if they throw away any of their possessions.',
            'All of my possessions will be useful someday, even the broken ones.',
            'I must keep everything that I buy',
          ],
        ],
      },
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: 'Common Obssessive Thoughts',
            bullets: [
              'Fear of being contaminated by germs or spreading germs to other',
              'Fear of losing self-control',
              'Uncontrollable, disturbing thoughts and image',
              'Deeply bothered by lack of order and symmetry',
              'Excessively suspicious',
            ],
          },
          {
            listTitle: 'Common Compulsive Behaviors',
            bullets: [
              'Rechecking things excessively to make sure that they are in order',
              'Constantly checking in with people',
              'Performing random actions to reduce anxiety',
              'Hanging onto excessive amounts of useless items',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Treatment',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Medication: Antidepressants [Serotonin reuptake inhibitors (SRIs), selective serotonin reuptake inhibitors (SSRIs)]',
              'Psychotherapy: Cognitive behavior therapy (CBT)',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.helpguide.org/articles/anxiety/obssessive-compulsive-disorder-ocd.htm/',
              'http://beyondocd.org/information-for-individuals/clinical-definition-of-ocd',
              'https://www.nimh.nih.gov/health/topics/obsessive-compulsive-disorder-ocd/index.shtml',
              'https://www.nimh.nih.gov/health/publications/obsessive-compulsive-disorder-when-unwanted-thoughts-take-over/index.shtml',
              'https://report.nih.gov/nihfactsheets/ViewFactSheet.aspx?csid=54&key=O',
            ],
          },
        ],
      },
    },
  ],
}

export const ocdViet = {
  disorder: 'Rối loạn ám ảnh cưỡng chế',
  disorderExpanded: '(tiếng Anh: Obsessive-Compulsive Disorder - OCD)',
  content: [
    {
      type: 'text',
      title: 'Rối loạn ám ảnh cưỡng chế là gì?',
      summary:
        'Rối loạn ám ảnh cưỡng chế là một rối loạn tâm lý với dấu hiệu phổ biến là những ý nghĩ ám ảnh, lo lắng không có lý do chính đáng và phải thực hiện các hành vi có tính chất ép buộc để giảm bớt căng thẳng. Người bị ảnh hưởng của bệnh có những ý nghĩ và hành vi lặp lại một cách vô nghĩa mà không kiểm soát được. Ở Mỹ, OCD ảnh hưởng đến khoảng 2,2 triệu người Mỹ trưởng thành và thường bắt đầu trong thời kỳ thơ ấu.',
    },
    {
      type: 'table',
      title: 'Thể loại và ví dụ về triệu chứng:',
      summary: {
        rows: [
          ['Thể loại', 'triệu chứng', 'Ý nghĩ ám ảnh', 'Hành vi cưỡng chế'],
          [
            'Lau chùi và giặt giũ',
            'Sợ bị bẩn',
            'Tôi lo sợ vi khuẩn trên da tôi sẽ làm nhiễm trùng tôi',
            'Tôi rữa tay 5 lần liên tục để chắc chắn rằng tay tôi được sạch sẽ',
          ],
          [
            'Kiểm tra',
            'Liên tục kiểm tra mọi thứ có thể gây ra hoàn cảnh nguy hiểm',
            'Tôi lo lắng là tôi quên khóa cửa trước',
            'Tôi liên tục quay về nhà trong ngày để chắc chắn cửa nhà tôi vẫn được khóa.',
          ],
          [
            'Người nghi ngờ, người phạm tội',
            'Nếu hành động của họ không được hoàn thiện, một thứ khủng khiếp ngoài tầm kiểm soát của họ sẽ xảy ra',
            'Tôi lỡ lời nói không tốt về 1 người nào đó',
            'Tôi sẽ bị trừng phạt bởi một thế lực linh thiêng',
          ],
          [
            'Đếm và sắp xếp nhiều lần',
            'Ám ảnh với sự trật tự  và tính cân đối. Bao gồm: con số, màu sắc và thứ tự sắp xếp.',
            'Tôi không thích số 23',
            'Nếu tôi đếm 23 món đồ, tôi phải tiếp tục đếm những món khác để tránh số đó.',
          ],
          [
            'Sưu tầm và tích trữ',
            'Lo sợ hậu quả khó lường nếu như họ vứt bỏ bất cứ đồ vật nào của họ',
            'Tất cả những đồ vật của tôi sẽ trở nên hữu dụng trong một ngày nào đó, cho dù nó là đồ bị hư.',
            'Tôi phải cất giữ mọi thứ mà tôi mua. ',
          ],
        ],
      },
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu chứng ',
      summary: {
        list: [
          {
            listTitle: 'Ý nghĩ ám ảnh phổ biến:',
            bullets: [
              'Lo sợ bị làm bẩn bởi vi khuẩn hay là lây lan vi khuẩn đến người khác',
              'Lo sợ bị mất kiểm soát chính mình',
              'Những suy nghĩ lo âu và hình ảnh quấy rối',
              'Đòi hỏi tính cân đối và chính xác',
              'Nghi ngờ quá mức',
            ],
          },
          {
            listTitle: 'Hành vi cưỡng chế',
            bullets: [
              'Liên tục kiểm tra để chắc chắn mọi thứ theo thứ tự',
              'Thường xuyên để ý đến những người khác',
              'Thực hiện những hành vi bất chợt để giúp họ giảm bớt lo âu',
              'Tích trữ những đồ dùng không cần thiết',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Điều trị',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Thuốc: chống trầm cảm [(Serotonin Reuptake inhibitors (SRIs), selective serotonin reuptake inhibitors (SSRIs)]',
              'Tâm lý trị liệu: Phương pháp điều trị nhận thức cố gắng điều chỉnh hành vi (CBT)',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.helpguide.org/articles/anxiety/obssessive-compulsive-disorder-ocd.htm/',
              'http://beyondocd.org/information-for-individuals/clinical-definition-of-ocd',
              'https://www.nimh.nih.gov/health/topics/obsessive-compulsive-disorder-ocd/index.shtml',
              'https://www.nimh.nih.gov/health/publications/obsessive-compulsive-disorder-when-unwanted-thoughts-take-over/index.shtml',
              'https://report.nih.gov/nihfactsheets/ViewFactSheet.aspx?csid=54&key=O',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: ocd,
  vietnamese: ocdViet,
}
