import React, { useState, useRef, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { Burger } from './Burger'
import { Menu } from './Menu'
import { Context } from '../context/context'

import vietmind from '../../assets/vietmind-logo.png'

const ImageButton = styled.button`
  border: none;
  height: 50px;
  padding: 0;
`

const Logo = styled.img`
  width: 50px;
  height: auto;
  object-fit: contain;
`

const VietmindLink = styled(Link)`
  font-weight: 400;
  font-size: 20px;
  color: #858a93;
  letter-spacing: 1.31px;
  text-decoration: none;

  &:visited {
    color: #858a93;
  }
`

const Bar = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 20px;
  height: 70px;
  background: #fff;
  box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  z-index: 10;

  display: ${({ showSidebarOnMobile }) =>
    showSidebarOnMobile ? 'none' : 'flex'};
  align-items: center;
  justify-content: space-between;

  position: fixed;
  right: 0;
`

export const MobileBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    showSidebarOnMobile,
    setShowSidebarOnMobile,
    setSelected
  } = useContext(Context)
  const node = useRef(null)
  useOnClickOutside(node, () => setIsOpen(false))

  return (
    <Bar showSidebarOnMobile={showSidebarOnMobile} role="region">
      <ImageButton
        aria-label="Open disorders menu"
        onClick={() => {
          setShowSidebarOnMobile(true)
          setSelected('disorders')
        }}
      >
        <Logo src={vietmind} alt="vietmind logo" />
      </ImageButton>
      <VietmindLink aria-label="Go to home" to="/home">
        VIETMIND
      </VietmindLink>
      <div ref={node}>
        <Burger isOpen={isOpen} setIsOpen={setIsOpen} />
        <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </Bar>
  )
}
