export const autism = {
  disorder: 'Autism Spectrum Disorder',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is Autism Spectrum Disorder?',
      summary:
        'Autism Spectrum Disorder (ASD) is a developmental disorder that causes varying degrees of social impairment, restricted interests, and repetitive behaviors. It is referred to as a “developmental disorder” because symptoms typically emerge within the first two years of life. However, ASD can be diagnosed at any age. The severity and type of disabilities in ASD can range across a wide “spectrum”, affecting different people in very different ways. ASD occurs about four times more in boys than girls and affects approximately 6 in 1000 children.',
    },
    {
      type: 'text',
      title: 'Autism Spectrum Disorder Misconceptions',
      summary:
        'Just because someone is lacking in social skills or does not follow social norms does not mean that they have autism. There is a rigorous diagnosis process that is used to evaluate if a person falls somewhere in the Autism Spectrum. \n Autism also does not simply make people bad at socializing. It may affect their daily lives and be detrimental to their work and academic performance as well.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle: 'Early Indicators (Young Children)',
            bullets: [
              'No babbling or pointing by age 1',
              'No single words by age 16 months or two-word phrases by age 2',
              'No response to name',
              'Loss of language or social skills previously acquired',
              'Poor eye contact',
              'Excessive lining up of toys or objects',
              'No smiling or social responsiveness',
              'Flapping their hands, rocking their body, or spinning in circles',
              'Having unusual and sometimes intense reactions to the way things smell, taste, feel, and/or look',
            ],
          },
          {
            listTitle: 'Later Indicators',
            bullets: [
              'Impaired ability to make friends with peers',
              'Impaired ability to initiate or sustain a conversation with others',
              'Absence or impairment of imaginative and social play',
              'Repetitive or unusual use of language ',
              'Abnormally intense or focused interest',
              'Preoccupation with certain objects or subjects',
              'Inflexible adherence to specific routines or rituals ',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Treatments',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Therapeutic Intervention: Behavioral, psychological, educational therapies',
              'Medications: Antipsychotic medications ',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/autism/what-is-autism-spectrum-disorder',
              'https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Fact-Sheets/Autism-Spectrum-Disorder-Fact-Sheet',
              'https://www.nimh.nih.gov/health/topics/autism-spectrum-disorders-asd/index.shtml',
              'Faras, H., Al Ateeqi, N., & Tidmarsh, L. (2010). Autism spectrum disorders. Annals of Saudi medicine, 30(4), 295-300.',
              'Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition (DSM-5). American Psychiatric Association. (2013).',
            ],
          },
        ],
      },
    },
  ],
}

export const autismViet = {
  disorder: 'Chứng rối loạn phổ tự kỷ',
  disorderExpanded: '(tiếng Anh: Autism Spectrum Disorder- ASD)',
  content: [
    {
      type: 'text',
      title: 'Bệnh rối loạn phổ tự kỷ là gì?',
      summary:
        'Bệnh rối loạn phổ tự kỷ là một chứng rối loạn phát triển đặc trưng bởi khiếm khuyết về mặt quan hệ nhân sinh, giao tiếp ngôn ngữ, giao tiếp phi ngôn ngữ và hành vi sở thích hạn chế và lặp đi lặp lại. Nó được biết như là một chứng “rối loạn phát triển” với những dấu hiệu thường được nhận thấy trong hai năm đầu đời. Tuy nhiên, bệnh  rối loạn phổ tự kỷ có thề được chẩn đoán ở mọi lứa tuổi. Mức độ nghiêm trọng và thể loại của bệnh có trong phạm vi rộng và ảnh hưởng nhiều người với nhiều cách khác nhau. Bệnh thường xuyên xảy ra bốn lần ở các bé trai nhiều hơn bé gái và ảnh hưởng khoảng chừng 6 trong 1000 trẻ em.',
    },
    {
      type: 'text',
      title: 'Những quan niệm không đúng về bệnh rối loạn phổ tự kỷ:',
      summary:
        'Chỉ bởi vì một người nào đó thiếu đi những kỹ năng xã hội và không theo quy luật của xã hội không có nghĩa rằng họ bị bệnh rối loạn phổ tự kỷ. Có nhiều quá trình chuẩn đoán nghiêm ngặt được sử dụng để đánh giá mức độ của bệnh rối loạn phổ tự kỷ cho một người. Bệnh rối loạn phổ tự kỷ không chỉ đơn giản là khiếm khuyết về mặt giao tiếp xã hội. Nó ảnh hưởng nghiêm trọng đến cuộc sống hàng ngày, đặc biệt là trong công việc và sự học tập.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu chứng:',
      summary: {
        list: [
          {
            listTitle: 'Dấu hiệu nhận biết sớm (trẻ nhỏ):',
            bullets: [
              'Không bập bẹ hoặc chỉ trỏ trước 1 tuổi',
              'Không nói được từ nào ở tuổi 16 tháng; Không nói được cụm từ có 2 chữ trước 2 tuổi',
              'Không trả lời khi nghe gọi tên mình',
              'Mất kỹ năng ngôn ngữ hay giao tiếp xã hội mà trước đây đạt được',
              'Không nhìn thẳng vào mắt người khác',
              'Rất thích sắp xếp đồ vật theo thứ tự',
              'Không mỉm cười hoặc phản xạ khi có những tín hiệu giao tiếp từ người khác',
              'Liên tục vẩy tay, đu bập bênh hoặc quay vòng',
              'Có những phản ứng bất thường về khứu giác, thị giác, cảm giác',
            ],
          },
          {
            listTitle: 'Dấu hiệu nhận biết về sau:',
            bullets: [
              'Hạn chế trong việc kết bạn',
              'Khó có thể tự mình bắt đầu một cuộc trò chuyện, những người mắc chứng tự kỷ rất khó khăn để tiếp tục duy trì một cuộc trò chuyện',
              'Không có hoặc hạn chế khả năng tưởng tượng và chơi đóng vai',
              'Hay lặp đi lặp lại trong việc sử dụng ngôn ngữ',
              'Chỉ đặt sự tập trung vào một bộ phận cụ thể nào đó của các món đồ quen thuộc',
              'Tỏ ra quan tâm và lo lắng mãnh liệt về một chủ đề nhất định nào đó',
              'Bám chặt và không thể thay đổi với những thói quen và lễ nghi',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Điều trị:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Trị liệu: cách cư xử, tâm lý, giáo dục',
              'Thuốc: thuốc chống rối loạn thần kinh Antipsychotic',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.psychiatry.org/patients-families/autism/what-is-autism-spectrum-disorder',
              'https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Fact-Sheets/Autism-Spectrum-Disorder-Fact-Sheet',
              'https://www.nimh.nih.gov/health/topics/autism-spectrum-disorders-asd/index.shtml',
              'Faras, H., Al Ateeqi, N., & Tidmarsh, L. (2010). Autism spectrum disorders. Annals of Saudi medicine, 30(4), 295-300.',
              'Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition (DSM-5). American Psychiatric Association. (2013).',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: autism,
  vietnamese: autismViet,
}
