export const depression = {
  disorder: 'Depression',
  disorderExpanded: '',
  content: [
    {
      type: 'text',
      title: 'What is depression?',
      summary:
        'Depression is a condition in which feelings of emptiness, low energy, lack of motivation, hopelessness, and/or sadness persist for longer than two weeks. An individual trapped in this depressive state may have difficulty following through with his or her daily routine or completing basic tasks. Depression can emerge at any age, with approximately 13.3% of all U.S. adolescents and 7.1% of all U.S. adults experiencing a major depressive episode in 2017. Different forms of depression include: persistent depressive disorder, postpartum depression, psychotic depression, seasonal affective disorder, and bipolar depression.',
    },
    {
      type: 'text',
      title: 'Misconceptions about depression',
      summary:
        'Depression is much more than just feeling sad or a “phase” to “get over”. It is a debilitating illness that makes living life much more difficult. Because individuals who suffer from depression often struggle with their day-to-day activities and long-term goals, they are often seen as simply weak and lazy—when in fact they are struggling against a condition that suffocates their motivation. Treating depression requires a very carefully-planned medical, therapeutic, and lifestyle intervention. It is not something that can be fixed by simply “toughening up” or being more positive.',
    },
    {
      type: 'list',
      title: 'Signs and Symptoms',
      summary: {
        list: [
          {
            listTitle:
              'The following signs and symptoms last for more than two weeks:',
            bullets: [
              'Sad, anxious, or “empty” mood',
              'Hopeless, pessimistic feelings',
              'Irritability',
              'Anhedonia (lack of enjoyment in pleasurable activities such as hobbies or sex)',
              'Chronic fatigue',
              'Difficulty sleeping/oversleeping',
              'Difficulty concentrating',
              'Suicidal thoughts',
              'Drastic changes to appetite',
              'Aches and pains without a clear physical cause',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Treatments',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Medications: Antidepressants, mood stabilizers, and antipsychotic medications',
              'Psychotherapy: Cognitive behavioral therapy, family-focused therapy, and interpersonal therapy',
              'Brain stimulation therapies: Electroconvulsive therapy, repetitive transcranial magnetic stimulation',
              'Exercise intervention',
              'Dietary intervention',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'References',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.nimh.nih.gov/health/topics/depression/index.shtml',
              'https://www.nimh.nih.gov/health/statistics/major-depression.shtml',
              'https://www.nami.org/NAMI/media/NAMI-Media/Images/FactSheets/Depression-FS.pdf',
            ],
          },
        ],
      },
    },
  ],
}

export const depressionViet = {
  disorder: 'Rối loạn trầm cảm',
  disorderExpanded: '(Tiếng anh: Depression)',
  content: [
    {
      type: 'text',
      title: 'Rối loạn trầm cảm là gì?',
      summary:
        'Trầm cảm là một trạng thái gây ra cảm giác trống rỗng, ít năng lượng, thiếu động lực, cảm thấy vô vọng và hoặc là buồn chán xảy ra hơn 2 tuần. Những cá nhân rơi vào trạng thái trầm cảm hay gặp khó khăn hay không thể thực hiện những việc bình thường. Trầm cảm xảy ra ở mọi lứa tuổi, khoảng chừng 13% tuổi thanh thiếu niên ở Mỹ và khoảng 7.1% người trưởng thành trải qua giai đoạn trầm cảm trong năm 2017. Có nhiều dạng trầm cảm khác nhau bao gồm: Rối loạn trầm cảm kéo dài, trầm cảm nặng kèm theo những biểu hiện thần kinh, trầm cảm sau sinh, trầm cảm theo mùa, và rối loạn lưỡng cực.',
    },
    {
      type: 'text',
      title: 'Những quan niệm không đúng về trầm cảm:',
      summary:
        'Trầm cảm không chỉ là cảm giác buồn chán hoặc là muốn vượt qua 1 giai đoạn nào đó. Trầm cảm là 1 loại bệnh làm suy nhược làm cho cuộc sống hằng ngày khó khăn hơn. Bởi vì những cá nhân mắc phải những bệnh này thường phải đấu tranh với những khó khăn trong hoạt động hàng ngày và ngay cả những mục đích lâu dài. Họ thường được cho là yếu ớt và lười biếng- trong khi đó họ phải đấu tranh chống lại những hoàn cảnh bóp nghẹt những động lực của họ. Điều trị bệnh trầm cảm đòi hỏi phải có kế hoạch cẩn thận, phương pháp chữa bệnh, và sự can thiệp vào lối sống. Nó không chỉ đơn giản là tập cứng rắn và lạc quan hơn.',
    },
    {
      type: 'list',
      title: 'Dấu hiệu và triệu trứng:',
      summary: {
        list: [
          {
            listTitle:
              'Đây là những dấu hiệu và triệu trứng kéo dài hơn 2 tuần:',
            bullets: [
              'Buồn chán, lo âu, cảm giác trống rỗng',
              'Tuyệt vọng và bi quan',
              'Dễ nổi cáu',
              'Vô cảm',
              'Mệt mỏi thường xuyên',
              'Mất ngủ hoặc ngủ nhiều.',
              'Khó tập trung',
              'Có ý nghĩ tự vẫn',
              'Giảm hoặc tăng cảm giác ngon miệng.',
              'Đau đớn về thể chất mà không có nguyên do',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Điều trị',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'Thuốc: chống trầm cảm , ổn định tinh thần, và chống loạn thần',
              'Tâm lý trị liệu: Phương pháp điều trị nhận thức cố gắng điều chỉnh hành vi (CBT), điều trị tập trung vào gia đình, và trị liệu giao tiếp cá nhân',
              'Điều trị kích thích trí não: điều trị bằng choáng điện',
              'Can thiệp tập luyện thể chất',
              'Can thiệp dinh dưỡng',
            ],
          },
        ],
      },
    },
    {
      type: 'list',
      title: 'Liên kết ngoài:',
      summary: {
        list: [
          {
            listTitle: '',
            bullets: [
              'https://www.nimh.nih.gov/health/topics/depression/index.shtml',
              'https://www.nimh.nih.gov/health/statistics/major-depression.shtml',
              'https://www.nami.org/NAMI/media/NAMI-Media/Images/FactSheets/Depression-FS.pdf',
            ],
          },
        ],
      },
    },
  ],
}

export default {
  english: depression,
  vietnamese: depressionViet,
}
